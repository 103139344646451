import { Component } from "react";
import { Redirect } from "react-router-dom";
import { Divider, Menu, Segment } from "semantic-ui-react";

import AllocationComponent from "components/Administration/OrganisationManagment/ViewOrganisation/Allocation/AllocationComponent";
import { AllocationConfigurationProvider } from "components/Administration/OrganisationManagment/ViewOrganisation/Allocation/AllocationConfigProvider";
import AutomatedAllocationComponent from "components/Administration/OrganisationManagment/ViewOrganisation/Allocation/AutomatedAllocationComponent";
import EditDetailsComponent from "components/Administration/OrganisationManagment/ViewOrganisation/EditOrganisationDetailsComponent";
import EditExclusionsComponent from "components/Administration/OrganisationManagment/ViewOrganisation/Exclusions/EditExclusionsComponent";
import EditHistoryQuestionComponent from "components/Administration/OrganisationManagment/ViewOrganisation/HistoryQuestions/EditHistoryQuestionComponent";
import EditLocationsComponent from "components/Administration/OrganisationManagment/ViewOrganisation/Locations/LocationsComponent";
import OrganisationNotificationsComponent from "components/Administration/OrganisationManagment/ViewOrganisation/Notifications/OrganisationNotifications";
import OutcomeLetters from "components/Administration/OrganisationManagment/ViewOrganisation/OutcomeLetters/OutcomeLetters";
import PersonalData from "components/Administration/OrganisationManagment/ViewOrganisation/PersonalData/PersonalData";
import { ReviewSettingsContextProvider } from "components/Administration/OrganisationManagment/ViewOrganisation/Review/ReviewSettingsProvider";
import ReviewSettingsScreen from "components/Administration/OrganisationManagment/ViewOrganisation/Review/ReviewSettingsScreen";
import ReviewSettingsNscScreen from "components/Administration/OrganisationManagment/ViewOrganisation/ReviewNsc/ReviewSettingsNscScreen";
import SuborganisationsComponent from "components/Administration/OrganisationManagment/ViewOrganisation/Suborganisations/SuborganisationsComponent";
import TemplatesComponent from "components/Administration/OrganisationManagment/ViewOrganisation/Templates/TemplatesComponent";
import Consent from "components/Administration/OrganisationManagment/ViewOrganisation/Consent";
import LoadingSpinner from "components/templates/LoadingSpinner";
import OrganisationInheritedWarning from "components/templates/OrganisationInheritedWarning";
import OverdueSettings from "components/Administration/OrganisationManagment/ViewOrganisation/Overdue";
import LandingPage from "components/Administration/OrganisationManagment/ViewOrganisation/LandingPage/LandingPage";

import { getModifiedInheritedProperties } from "helpers/organisation";

import { OrganisationMenuItems } from "model/administrationPages";
import { IHistoryQuestionType, IOverdue, IAutoAllocation, IOrganisation, IOverdueUpdate } from "model/organisation";
import { IRegistrationTexts } from "model/registration";
import { UserRole } from "model/userRole";

import { HOME } from "navigation/routes";

import organizationService from "services/organizationService";
import userService from "services/userService";

export const ORGANISATION_INHERITANCE_PROPERTY = {
    [IHistoryQuestionType.LESION]: "lesionHistoryQuestions",
    [IHistoryQuestionType.SKIN_COMPLAINT]: "skinComplaintQuestions",
    [IHistoryQuestionType.MEDICAL]: "medicalHistoryQuestions",
    [IHistoryQuestionType.NSC_MEDICAL]: "nscMedicalHistoryQuestions",
    [IHistoryQuestionType.BIOPSY]: "biopsyQuestions",
    [OrganisationMenuItems.PERSONAL_DATA]: "definedPersonalData",
    [OrganisationMenuItems.EXCLUSIONS]: "lesionExclusions",
    [OrganisationMenuItems.REVIEW_SETTINGS]: "reviewDiagnoses",
    [OrganisationMenuItems.TEMPLATES]: "templates",
    [OrganisationMenuItems.CASE_ALLOCATION]: "allocationConfiguration",
    REVIEW_MANAGEMENT_OPTIONS: "reviewManagementOptions",
    NOTIFICATION_CONFIGURATION: "notificationConfiguration",
};

const testId = "organisation-management-tab";
const allOrganisationMenuItems = [
    OrganisationMenuItems.ORGANISATION_DETAILS,
    OrganisationMenuItems.CONSENT,
    OrganisationMenuItems.CASE_ALLOCATION,
    OrganisationMenuItems.LOCATIONS,
    OrganisationMenuItems.NOTIFICATIONS,
    OrganisationMenuItems.TEMPLATES,
    OrganisationMenuItems.PERSONAL_DATA,
    OrganisationMenuItems.MEDICAL_HISTORY,
    OrganisationMenuItems.LESION_HISTORY,
    OrganisationMenuItems.NSC_MEDICAL_HISTORY,
    OrganisationMenuItems.SKIN_COMPLAINT_HISTORY,
    OrganisationMenuItems.BIOPSY,
    OrganisationMenuItems.EXCLUSIONS,
    OrganisationMenuItems.REVIEW_SETTINGS,
    OrganisationMenuItems.NSC_REVIEW_SETTINGS,
    OrganisationMenuItems.OVERDUE_SETTINGS,
    OrganisationMenuItems.OUTCOME_LETTERS,
    OrganisationMenuItems.SUBORGANISATIONS,
    OrganisationMenuItems.LANDING_PAGE_DATA,
];
const allOrganisationMenuTitles = {};
allOrganisationMenuTitles[OrganisationMenuItems.ORGANISATION_DETAILS] = "Details";
allOrganisationMenuTitles[OrganisationMenuItems.CONSENT] = "Consent";
allOrganisationMenuTitles[OrganisationMenuItems.CASE_ALLOCATION] = "Case Allocation";
allOrganisationMenuTitles[OrganisationMenuItems.LOCATIONS] = "Locations";
allOrganisationMenuTitles[OrganisationMenuItems.NOTIFICATIONS] = "Notifications";
allOrganisationMenuTitles[OrganisationMenuItems.TEMPLATES] = "Templates";
allOrganisationMenuTitles[OrganisationMenuItems.PERSONAL_DATA] = "Personal Data";
allOrganisationMenuTitles[OrganisationMenuItems.MEDICAL_HISTORY] = "Medical History";
allOrganisationMenuTitles[OrganisationMenuItems.LESION_HISTORY] = "Lesion History";
allOrganisationMenuTitles[OrganisationMenuItems.NSC_MEDICAL_HISTORY] = "Medical History - OSC";
allOrganisationMenuTitles[OrganisationMenuItems.SKIN_COMPLAINT_HISTORY] = "Skin Complaint History - OSC";
allOrganisationMenuTitles[OrganisationMenuItems.BIOPSY] = "Biopsy";
allOrganisationMenuTitles[OrganisationMenuItems.EXCLUSIONS] = "Exclusions";
allOrganisationMenuTitles[OrganisationMenuItems.REVIEW_SETTINGS] = "Review Settings";
allOrganisationMenuTitles[OrganisationMenuItems.NSC_REVIEW_SETTINGS] = "Review Settings - OSC";
allOrganisationMenuTitles[OrganisationMenuItems.OVERDUE_SETTINGS] = "Overdue Settings";
allOrganisationMenuTitles[OrganisationMenuItems.OUTCOME_LETTERS] = "Outcome Letters";
allOrganisationMenuTitles[OrganisationMenuItems.SUBORGANISATIONS] = "Suborganisations";
allOrganisationMenuTitles[OrganisationMenuItems.LANDING_PAGE_DATA] = "Landing Page";

class OrganisationDetailsScreen extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            activeItem: OrganisationMenuItems.ORGANISATION_DETAILS,
            errorDetails: [],
            isError: false,
            mode: "view",
            organisation: null,
            inheritedTabs: [],
        };
    }

    public componentDidMount() {
        this.getOrganisation();
    }

    public componentDidUpdate() {
        const { params } = this.props;
        const { organisation } = this.state;

        if (organisation && params.uuid !== organisation.uuid) {
            organizationService.getOrganisationByUuid(params.uuid).then((result: IOrganisation) => {
                this.setState({
                    activeItem: OrganisationMenuItems.ORGANISATION_DETAILS,
                    organisation: result,
                    inheritedTabs: getModifiedInheritedProperties(result, true),
                });
            });
        }
    }

    private setOrganisationWorkingDays = (workingDays: string[]) => {
        this.setState((prevState) => ({
            organisation: { ...prevState.organisation, workingDays },
        }));
    };

    private setRegistrationTexts = (registrationTexts: IRegistrationTexts) => {
        this.setState((prevState) => ({
            organisation: { ...prevState.organisation, registrationTexts },
        }));
    };

    private getOrganisation() {
        const { params } = this.props;

        organizationService.getOrganisationByUuid(params.uuid).then((result) => {
            this.setState({
                organisation: result,
                inheritedTabs: getModifiedInheritedProperties(result, true),
                workingDaysTemp: result.workingDays,
            });
        });
    }

    private getSelectedTabContent() {
        const { activeItem, organisation } = this.state;
        switch (activeItem) {
            case OrganisationMenuItems.ORGANISATION_DETAILS:
                return this.getDetailView(organisation);
            case OrganisationMenuItems.MEDICAL_HISTORY:
                return this.getMedicalHistory(organisation, activeItem);
            case OrganisationMenuItems.NSC_MEDICAL_HISTORY:
                return this.getNSCMedicalHistory(organisation, activeItem);
            case OrganisationMenuItems.SKIN_COMPLAINT_HISTORY:
                return this.getSkinComplaintHistory(organisation, activeItem);
            case OrganisationMenuItems.LESION_HISTORY:
                return this.getLesionHistory(organisation, activeItem);
            case OrganisationMenuItems.BIOPSY:
                return this.getBiopsyHistory(organisation, activeItem);
            case OrganisationMenuItems.EXCLUSIONS:
                return this.getExclusions(organisation);
            case OrganisationMenuItems.LOCATIONS:
                return <EditLocationsComponent locations={organisation.locations} organisation={organisation} />;
            case OrganisationMenuItems.PERSONAL_DATA:
                return this.getPersonalData(organisation);
            case OrganisationMenuItems.TEMPLATES:
                return this.getTemplates(organisation);
            case OrganisationMenuItems.CONSENT:
                return this.getConsentSettings(organisation);
            case OrganisationMenuItems.REVIEW_SETTINGS:
                return this.getReviewSettings(organisation);
            case OrganisationMenuItems.NSC_REVIEW_SETTINGS:
                return this.getNscReviewSettings(organisation);
            case OrganisationMenuItems.OVERDUE_SETTINGS:
                return this.getOverdueSettings(organisation);
            case OrganisationMenuItems.NOTIFICATIONS:
                return this.getNotifications(organisation); //
            case OrganisationMenuItems.OUTCOME_LETTERS:
                return <OutcomeLetters />;
            case OrganisationMenuItems.SUBORGANISATIONS:
                return <SuborganisationsComponent organisation={organisation} />;
            case OrganisationMenuItems.LANDING_PAGE_DATA:
                return this.getLandingPageData(organisation);
            case OrganisationMenuItems.CASE_ALLOCATION:
                return this.getAllocationConfig(organisation);
            default:
                return <></>;
        }
    }

    private getDetailView(organisation: IOrganisation) {
        return (
            <EditDetailsComponent
                organisation={organisation}
                updateOrganisation={this.updateOrganisation}
                setOrganisationWorkingDays={this.setOrganisationWorkingDays}
            />
        );
    }

    private getMedicalHistory(organisation: IOrganisation, key: IHistoryQuestionType) {
        const showEditButton = this.getShowEditButton(IHistoryQuestionType.MEDICAL);

        return (
            <EditHistoryQuestionComponent
                key={key}
                organisation={organisation}
                questionType={IHistoryQuestionType.MEDICAL}
                title="Medical History Questions"
                historyQuestions={organisation.medicalHistoryQuestions}
                updateOrganisation={this.updateOrganisation}
                showEditButton={showEditButton}
                addQuestion={this.addQuestion(ORGANISATION_INHERITANCE_PROPERTY[IHistoryQuestionType.MEDICAL])}
                deleteQuestion={this.deleteQuestion(ORGANISATION_INHERITANCE_PROPERTY[IHistoryQuestionType.MEDICAL])}
            />
        );
    }

    private getNSCMedicalHistory(organisation: IOrganisation, key: IHistoryQuestionType) {
        const showEditButton = this.getShowEditButton(IHistoryQuestionType.NSC_MEDICAL);

        return (
            <EditHistoryQuestionComponent
                key={key}
                organisation={organisation}
                questionType={IHistoryQuestionType.NSC_MEDICAL}
                title="Medical History - OSC"
                historyQuestions={organisation.nscMedicalHistoryQuestions}
                updateOrganisation={this.updateOrganisation}
                showEditButton={showEditButton}
                addQuestion={this.addQuestion(ORGANISATION_INHERITANCE_PROPERTY[IHistoryQuestionType.NSC_MEDICAL])}
                deleteQuestion={this.deleteQuestion(
                    ORGANISATION_INHERITANCE_PROPERTY[IHistoryQuestionType.NSC_MEDICAL]
                )}
            />
        );
    }

    private getLesionHistory(organisation: IOrganisation, key: IHistoryQuestionType) {
        const showEditButton = this.getShowEditButton(IHistoryQuestionType.LESION);

        return (
            <EditHistoryQuestionComponent
                key={key}
                organisation={organisation}
                questionType={IHistoryQuestionType.LESION}
                title="Lesion History Questions"
                historyQuestions={organisation.lesionHistoryQuestions}
                updateOrganisation={this.updateOrganisation}
                showEditButton={showEditButton}
                addQuestion={this.addQuestion(ORGANISATION_INHERITANCE_PROPERTY[IHistoryQuestionType.LESION])}
                deleteQuestion={this.deleteQuestion(ORGANISATION_INHERITANCE_PROPERTY[IHistoryQuestionType.LESION])}
            />
        );
    }

    private getSkinComplaintHistory(organisation: IOrganisation, key: IHistoryQuestionType) {
        const showEditButton = this.getShowEditButton(IHistoryQuestionType.SKIN_COMPLAINT);

        return (
            <EditHistoryQuestionComponent
                key={key}
                organisation={organisation}
                questionType={IHistoryQuestionType.SKIN_COMPLAINT}
                title="Skin Complaint History - OSC"
                historyQuestions={organisation.skinComplaintQuestions}
                updateOrganisation={this.updateOrganisation}
                showEditButton={showEditButton}
                addQuestion={this.addQuestion(ORGANISATION_INHERITANCE_PROPERTY[IHistoryQuestionType.SKIN_COMPLAINT])}
                deleteQuestion={this.deleteQuestion(
                    ORGANISATION_INHERITANCE_PROPERTY[IHistoryQuestionType.SKIN_COMPLAINT]
                )}
            />
        );
    }

    private getBiopsyHistory(organisation: IOrganisation, key: IHistoryQuestionType) {
        const showEditButton = this.getShowEditButton(IHistoryQuestionType.LESION);

        return (
            <EditHistoryQuestionComponent
                key={key}
                organisation={organisation}
                questionType={IHistoryQuestionType.BIOPSY}
                title="Biopsy Questions"
                historyQuestions={organisation.biopsyQuestions}
                updateOrganisation={this.updateOrganisation}
                showEditButton={showEditButton}
                addQuestion={this.addQuestion(ORGANISATION_INHERITANCE_PROPERTY[IHistoryQuestionType.BIOPSY])}
                deleteQuestion={this.deleteQuestion(ORGANISATION_INHERITANCE_PROPERTY[IHistoryQuestionType.BIOPSY])}
            />
        );
    }

    private getExclusions(organisation: IOrganisation) {
        const showEditButton = this.getShowEditButton(OrganisationMenuItems.EXCLUSIONS);

        return (
            <EditExclusionsComponent
                title="Exclusions"
                exclusions={organisation.lesionExclusions}
                organisation={organisation}
                showEditButton={showEditButton}
                updateInheritanceData={this.updateInheritanceData}
            />
        );
    }

    private getPersonalData(organisation: IOrganisation) {
        const showEditButton = this.getShowEditButton(OrganisationMenuItems.PERSONAL_DATA);
        const { uuid: organisationUuid, parentUuid } = organisation;

        return (
            <PersonalData
                showEditButton={showEditButton}
                organisationUuid={organisationUuid}
                parentOrganisationUuid={parentUuid}
                updateInheritanceData={this.updateInheritanceData}
            />
        );
    }

    private getTemplates(organisation: IOrganisation) {
        const showEditButton = this.getShowEditButton(OrganisationMenuItems.TEMPLATES);

        return (
            <TemplatesComponent
                organisation={organisation}
                showEditButton={showEditButton}
                updateInheritanceData={this.updateInheritanceData}
            />
        );
    }

    private getConsentSettings(organisation: IOrganisation) {
        return <Consent organisation={organisation} updateOrganisation={this.updateOrganisation} />;
    }

    private getLandingPageData(organisation: IOrganisation) {
        return <LandingPage organisation={organisation} setRegistrationTexts={this.setRegistrationTexts} />;
    }

    private getAllocationConfig(organisation: IOrganisation) {
        const { parentUuid = undefined, uuid: organisationUuid } = organisation;
        const showEditButton = this.getShowEditButton(OrganisationMenuItems.CASE_ALLOCATION);

        const onSave = (autoAssignConfig: IAutoAllocation) => {
            this.setState({
                organisation: {
                    ...organisation,
                    autoAssignConfig: [autoAssignConfig],
                },
            });
        };

        return (
            <>
                <AllocationConfigurationProvider>
                    <AllocationComponent
                        organisationUuid={organisationUuid}
                        showEditButton={showEditButton}
                        updateInheritanceData={this.updateInheritanceData}
                        parentUuid={parentUuid}
                    />
                    <AutomatedAllocationComponent organisation={organisation} onSave={onSave} />
                </AllocationConfigurationProvider>
            </>
        );
    }

    private getReviewSettings(organisation: IOrganisation) {
        const showEditButton = this.getShowEditButton(OrganisationMenuItems.REVIEW_SETTINGS);

        return (
            <ReviewSettingsContextProvider>
                <ReviewSettingsScreen
                    organisation={organisation}
                    showEditButton={showEditButton}
                    updateInheritanceData={this.updateInheritanceData}
                />
            </ReviewSettingsContextProvider>
        );
    }

    private getNscReviewSettings(organisation: IOrganisation) {
        const showEditButton = this.getShowEditButton(OrganisationMenuItems.NSC_REVIEW_SETTINGS);

        return (
            <ReviewSettingsNscScreen
                organisation={organisation}
                showEditButton={showEditButton}
                updateInheritanceData={this.updateInheritanceData}
            />
        );
    }

    private getOverdueSettings(organisation: IOrganisation) {
        return <OverdueSettings onSave={this.onOverdueSettingChange} organisation={organisation} />;
    }

    private getNotifications(organisation: IOrganisation) {
        const showEditButton = this.getShowEditButton("NOTIFICATION_CONFIGURATION");
        return <OrganisationNotificationsComponent organisation={organisation} showEditButton={showEditButton} />;
    }

    private getMenu() {
        const { activeItem, inheritedTabs, organisation } = this.state;
        const { overdueDefinitions, nonSkinCancerAllowed, codeName } = organisation;
        let organisationMenuItems = allOrganisationMenuItems;
        if (!nonSkinCancerAllowed) {
            organisationMenuItems = allOrganisationMenuItems.filter(
                (item) =>
                    item !== OrganisationMenuItems.NSC_MEDICAL_HISTORY &&
                    item !== OrganisationMenuItems.SKIN_COMPLAINT_HISTORY &&
                    item !== OrganisationMenuItems.NSC_REVIEW_SETTINGS
            );
        }
        if (!(overdueDefinitions?.length && userService.checkUserHasRole([UserRole.SUPERADMIN]))) {
            organisationMenuItems = organisationMenuItems.filter(
                (item) => item !== OrganisationMenuItems.OVERDUE_SETTINGS
            );
        }
        if (!(codeName && userService.checkUserHasRole([UserRole.SUPERADMIN]))) {
            organisationMenuItems = organisationMenuItems.filter(
                (item) => item !== OrganisationMenuItems.LANDING_PAGE_DATA
            );
        }

        return (
            <>
                {organisationMenuItems.map((item) => (
                    <Menu pointing stackable secondary compact key={item}>
                        <Menu.Item
                            active={activeItem === item}
                            onClick={this.handleMenuClick}
                            action={item}
                            tabIndex={0}
                            onKeyDown={(e: KeyboardEvent) => this.handleKeyPress(e, item)}
                            data-testid={testId}
                        >
                            {allOrganisationMenuTitles[item]}
                            {inheritedTabs[item] ? (
                                <OrganisationInheritedWarning
                                    organisationInheritedProperties={inheritedTabs[item]}
                                    isProperties
                                />
                            ) : null}
                        </Menu.Item>
                        <div />
                    </Menu>
                ))}
            </>
        );
    }

    private handleMenuClick = (_event: any, object: any) => {
        this.setState({ activeItem: object.action });
    };

    private handleKeyPress = (event: KeyboardEvent, action: string): void => {
        if (event.key === "Enter") {
            this.setState({ activeItem: action });
        }
    };

    private updateOrganisation = (result: any) => {
        const { organisation } = this.state;
        const copiedOrganisation = { ...organisation };
        delete copiedOrganisation.inheritanceData[ORGANISATION_INHERITANCE_PROPERTY[IHistoryQuestionType.LESION]];
        delete copiedOrganisation.inheritanceData[ORGANISATION_INHERITANCE_PROPERTY[IHistoryQuestionType.MEDICAL]];
        delete copiedOrganisation.inheritanceData[ORGANISATION_INHERITANCE_PROPERTY[IHistoryQuestionType.EXCLUSION]];
        const updatedOrganisation = { ...copiedOrganisation, ...result };
        this.setState({ organisation: updatedOrganisation });
    };

    private updateInheritanceData = (property: string) => {
        const { organisation } = this.state;
        const copiedOrganisation = { ...organisation };
        const { inheritanceData = {} } = { ...copiedOrganisation };
        const copiedInheritanceData = { ...inheritanceData };
        delete copiedInheritanceData[property];
        copiedOrganisation.inheritanceData = copiedInheritanceData;
        this.setState({ organisation: copiedOrganisation });
    };

    private getShowEditButton = (questionType: string) => {
        const { organisation } = this.state;
        const showEditButton = !!organisation.inheritanceData[ORGANISATION_INHERITANCE_PROPERTY[questionType]];

        return showEditButton;
    };

    private onOverdueSettingChange = (overdueUpdate: IOverdueUpdate & { uuid: string }) => {
        const { organisation } = this.state;
        const { overdueDefinitions } = organisation;
        const { uuid } = overdueUpdate;

        const updatedOverdueIndex = overdueDefinitions.findIndex((item: IOverdue) => item.uuid === uuid);

        if (updatedOverdueIndex < 0) {
            return;
        }

        const [...overdueDefinitionsUpdated] = overdueDefinitions;
        overdueDefinitionsUpdated[updatedOverdueIndex] = {
            ...overdueDefinitions[updatedOverdueIndex],
            ...overdueUpdate,
        };

        this.setState((prevState) => ({
            ...prevState,
            organisation: {
                ...organisation,
                overdueDefinitions: overdueDefinitionsUpdated,
            },
        }));
    };

    private addQuestion = (key: string) => (edit: any) => {
        const { organisation } = this.state;

        this.setState({
            organisation: {
                ...organisation,
                [key]: [...organisation[key], edit],
            },
        });
    };

    private deleteQuestion = (key: string) => (uuid: string) => {
        const { organisation } = this.state;

        this.setState({
            organisation: {
                ...organisation,
                [key]: organisation[key].filter((item: any) => item.uuid !== uuid),
            },
        });
    };

    public render() {
        const { organisation } = this.state;

        if (!userService.checkUserHasRole([UserRole.ADMIN, UserRole.SA_ADMIN, UserRole.SUPERADMIN])) {
            return <Redirect to={HOME} />;
        }

        if (organisation === null) {
            return <LoadingSpinner />;
        }

        return (
            <Segment>
                <h2>{organisation.name}</h2>
                <Divider />
                <div>
                    {this.getMenu()}
                    {this.getSelectedTabContent()}
                </div>
            </Segment>
        );
    }
}

export default OrganisationDetailsScreen;
