import { FC } from "react";
import { Segment, Grid } from "semantic-ui-react";

import StatusProgressColumn from "./Components/StatusProgressColumn";
import MainColumn from "./Components/MainColumn";

const OutcomeLetter: FC<any> = () => {
    const progressStatusObj = {
        format: true,
        content: false,
        activation: false,
    };

    return (
        <Segment>
            <Grid columns={2} divided>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <StatusProgressColumn progressStatusObj={progressStatusObj} />
                    </Grid.Column>
                    <Grid.Column width={10}>
                        <MainColumn />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default OutcomeLetter;
