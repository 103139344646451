export const getModalConfirmationVisible = (state: any) => state.registrationUI.isModalConfirmationVisible;

export const getModalEmailConfirmationVisible = (state: any) => state.registrationUI.isModalEmailConfirmationVisible;

export const getModalRedirectVisible = (state: any) => state.registrationUI.isModalRedirectVisible;

export const getIsPreathorisationNumberErrorModalVisible = (state: any) =>
    state.registrationUI.isPreathorisationNumberErrorModalVisible;

export const getIsAccountExistInDifferentOrganisationVisible = (state: any) =>
    state.registrationUI.isAccountExistInDifferentOrganisationVisible;
