import React, { FC, useState, useEffect, ChangeEvent } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Divider, Header, Input, InputOnChangeData, Loader } from "semantic-ui-react";

import CustomButton from "components/templates/Button";
import EmailConfirmationModal from "components/RemoteModel/modals/EmailConfirmationModal";
import RedirectModal from "components/RemoteModel/modals/RedirectModal";
import ErrorMessage from "components/ErrorMessage";
import PreAuthorisationNumberErrorModal from "components/RemoteModel/modals/PreathorisationNumberErrorModal";
import EmailExistForDifferentOrganisationErrorModal from "components/RemoteModel/modals/EmailExistForDifferentOrganisationErrorModal";

import {
    areStringsSimilar,
    validEmail,
    validPreauthorisationNumber,
    validMemberNumber,
    NOT_VALID_EMAIL_ERROR,
    NOT_SAME_EMAILS_ERRORS,
    NOT_VALID_MEMBER_NUMBER,
    NOT_VALID_PREAUTHORISATION_NUMBER,
} from "helpers/common";

import { IPersonalDataInput, RegistrationPersonalFields } from "model/registration";

import { history } from "App";
import { LOGIN } from "navigation/routes";

import * as actions from "redux/actions";
import { getRegistration } from "redux/selectors/registration";
import getFormErrors from "redux/selectors/formError";
import getValidateError from "redux/selectors/validateError";
import {
    getIsPreathorisationNumberErrorModalVisible,
    getModalEmailConfirmationVisible,
    getModalRedirectVisible,
    getIsAccountExistInDifferentOrganisationVisible,
} from "redux/selectors/registrationUI";

import registrationService from "services/registrationService";
import * as ErrorService from "services/errorService";

const initialState = {
    email: "",
    emailRepeated: "",
    preauthorisationNumber: "",
    memberNumber: "",
};

interface IInitialState {
    email: string;
    emailRepeated: string;
    preauthorisationNumber?: string;
    memberNumber?: string;
}

interface IErrors {
    email: boolean;
    emailRepeated: boolean;
    preauthorisationNumber: boolean;
    memberNumber: boolean;
    emailsDoNotMatch: boolean;
}

const RegisterScreen: FC = () => {
    const [form, setForm] = useState<IInitialState>(initialState);
    const [personalDataInputs, setPersonalDataInputs] = useState<IPersonalDataInput[]>([]);
    const [errorRequest, setErrorRequest] = useState<string>("");
    const [fieldErrors, setFieldErrors] = useState<IErrors>({
        email: false,
        emailRepeated: false,
        preauthorisationNumber: false,
        memberNumber: false,
        emailsDoNotMatch: false,
    });

    const dispatch = useDispatch();
    const registration = useSelector(getRegistration);
    const validateErrors = useSelector(getValidateError);
    const formErrors = useSelector(getFormErrors);
    const visibleEmailConfirmationModal = useSelector(getModalEmailConfirmationVisible);
    const isPreAuthorisationNumberErrorModalVisible = useSelector(getIsPreathorisationNumberErrorModalVisible);
    const isAccountExistInDifferentOrganisationVisible = useSelector(getIsAccountExistInDifferentOrganisationVisible);
    const visibleRedirectModal = useSelector(getModalRedirectVisible);

    useEffect(() => {
        if (registration.email) {
            history.push(registration.organisationCode);
        }
        registrationService
            .getRegistrationPersonalData(registration.organisationUuid)
            .then((result) => {
                const filteredResults = result.filter((personalData: IPersonalDataInput) => !personalData.removed);
                setPersonalDataInputs(filteredResults || []);
                setForm((prevState) => {
                    const prevForm = { ...prevState };
                    result
                        ?.filter((input: IPersonalDataInput) => !input.removed)
                        ?.forEach((input: IPersonalDataInput) => {
                            prevForm[input.propertyName] = "";
                        });
                    return prevForm;
                });
            })
            .catch(({ message }) => setErrorRequest(message));
    }, []);

    const { email, emailRepeated, preauthorisationNumber, memberNumber } = form;

    const onChange = (event: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        const { name, value } = data;

        setFieldErrors((prevState) => ({
            ...prevState,
            [name]: false,
            emailsDoNotMatch:
                name === RegistrationPersonalFields.EMAIL || name === RegistrationPersonalFields.EMAIL_REPEATED
                    ? false
                    : prevState.emailsDoNotMatch,
        }));
        setForm((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const onValidate = () => {
        const errors = {
            email: !validEmail(email),
            emailRepeated: !validEmail(emailRepeated),
            emailsDoNotMatch: !areStringsSimilar(email, emailRepeated),
            preauthorisationNumber: !validPreauthorisationNumber(preauthorisationNumber),
            memberNumber: !validMemberNumber(memberNumber),
        };
        setFieldErrors(errors);
        return Object.values(errors).every((err: boolean) => !err);
    };

    const renderErrorMessage = (propertyName: string): string | null => {
        switch (propertyName) {
            case RegistrationPersonalFields.EMAIL:
                return fieldErrors.email ? NOT_VALID_EMAIL_ERROR : null;
            case RegistrationPersonalFields.EMAIL_REPEATED:
                if (fieldErrors.emailRepeated) {
                    return NOT_VALID_EMAIL_ERROR;
                }
                return fieldErrors.emailsDoNotMatch ? NOT_SAME_EMAILS_ERRORS : null;
            case RegistrationPersonalFields.MEMBER_NUMBER:
                return fieldErrors.memberNumber ? NOT_VALID_MEMBER_NUMBER : null;
            case RegistrationPersonalFields.PREAUTHORISATION_NUMBER:
                return fieldErrors.preauthorisationNumber ? NOT_VALID_PREAUTHORISATION_NUMBER : null;
            default:
                return null;
        }
    };

    const onContinue = () => {
        if (onValidate()) {
            const preparedData = { ...form, registrationUuid: registration.registrationUuid };
            registrationService.sendRegistrationPersonalData(preparedData, dispatch).then(() => {
                dispatch(ErrorService.cleanErrors());
                dispatch(actions.registrationActions.userSetEmail(form.email));
            });
        }
    };

    const onBack = () => history.goBack();

    const hideEmailConfirmationModal = () => {
        history.push(registration.organisationCode);
        dispatch(actions.registrationUIActions.setIsModalEmailConfirmationVisible(false));
    };

    const hidePreAuthorisationNumberErrorModal = () =>
        dispatch(actions.registrationUIActions.setIsPreauthorisationNumberErrorModalVisible(false));

    const hideExistInDifferentOrganisationErrorModal = () =>
        dispatch(actions.registrationUIActions.setIsAccountExistInDifferentOrganisationVisible(false));

    const personalDetails = () => {
        if (personalDataInputs.length) {
            return (
                <>
                    {personalDataInputs.map((personalDataInput: IPersonalDataInput) => {
                        const { uuid, type, displayName, propertyName, required } = personalDataInput;

                        return (
                            <div key={uuid} className={`custom-input ${required ? "is-required" : ""}`}>
                                <p>{displayName}</p>
                                <Input
                                    error={fieldErrors[propertyName]}
                                    type={type}
                                    name={propertyName}
                                    value={form[propertyName]}
                                    onChange={onChange}
                                />

                                <div className="is-error">{renderErrorMessage(propertyName)}</div>
                            </div>
                        );
                    })}
                </>
            );
        }

        if (errorRequest) {
            return <ErrorMessage errors={errorRequest} />;
        }

        return (
            <div className="remote-model__form">
                <Loader active inline="centered" />
            </div>
        );
    };

    return (
        <>
            <Container className="custom__container">
                <div className="registration__wrapper">
                    <Header as="h1">Register</Header>
                    <Divider />
                    <p>To start, enter your email address and insurer information below.</p>
                    <p>
                        If you&apos;ve recently started a skin check and would like to continue it, please{" "}
                        <Link to={LOGIN}>click here</Link> to log in.
                    </p>
                    <div className="remote-model__form">
                        <div className="custom-input is-required">
                            <p>Email Address</p>
                            <Input
                                error={fieldErrors.email}
                                type="email"
                                name={RegistrationPersonalFields.EMAIL}
                                value={email}
                                onChange={onChange}
                            />

                            <div className="is-error">{renderErrorMessage(RegistrationPersonalFields.EMAIL)}</div>
                        </div>

                        <div className="custom-input is-required">
                            <p>Confirm Email Address</p>
                            <Input
                                error={fieldErrors.emailRepeated || fieldErrors.emailsDoNotMatch}
                                type="email"
                                name={RegistrationPersonalFields.EMAIL_REPEATED}
                                value={emailRepeated}
                                onChange={onChange}
                            />
                            <div className="is-error">
                                {renderErrorMessage(RegistrationPersonalFields.EMAIL_REPEATED)}
                            </div>
                        </div>
                    </div>

                    <div className="is-heading__small">Insurer Information</div>
                    <div className="remote-model__form">{personalDetails()}</div>

                    <div className="buttons-wrapper">
                        <CustomButton type="button" variant="empty" action={onBack} text="Back" />
                        <CustomButton
                            type="button"
                            variant="filled"
                            action={onContinue}
                            text="Continue"
                            disabled={!!errorRequest}
                        />
                    </div>

                    {validateErrors.load || formErrors.errors?.length ? (
                        <ErrorMessage errors={validateErrors?.load?.message || formErrors.errors[0]?.message} />
                    ) : null}
                </div>
            </Container>
            <EmailConfirmationModal
                show={visibleEmailConfirmationModal}
                email={email}
                goBack={hideEmailConfirmationModal}
                closeOnOverlay={false}
            />
            <PreAuthorisationNumberErrorModal
                show={isPreAuthorisationNumberErrorModalVisible}
                onBackClicked={hidePreAuthorisationNumberErrorModal}
                closeOnOverlay={false}
            />
            <EmailExistForDifferentOrganisationErrorModal
                show={isAccountExistInDifferentOrganisationVisible}
                onBackClicked={hideExistInDifferentOrganisationErrorModal}
                closeOnOverlay={false}
            />
            <RedirectModal show={visibleRedirectModal} closeOnOverlay={false} />
        </>
    );
};

export default RegisterScreen;
