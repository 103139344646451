import { isUndefined } from "helpers/common";
import { consentConfigurationChanged, doesOrganisationHaveLocations } from "helpers/assessment";

import { IAssessmentBase, ILesion } from "model/assessment";
import { ICase, IPatientData } from "model/case";
import { CaseListColumns, CaseListSearchFieldKeys } from "model/caseList";
import { RemoteTimeline } from "model/remoteCaseTimeline";
import { CaseStatus } from "model/caseStatus";
import { CaptureImageType } from "model/captureImageType";
import { DATA_IS_NOT_AVAILABLE_TEXT } from "model/common";
import { IOrganisation } from "model/organisation";
import PatientDetailsIntegration from "model/integrations";

import {
    REMOTE_MODEL_CASES_IN_REVIEW,
    REMOTE_MODEL_CASES_KIT_DELIVERY,
    REMOTE_MODEL_CASES_LESION_IMAGES,
    REMOTE_MODEL_CASES_MEDICAL_HISTORY,
    REMOTE_MODEL_CASES_REPORT,
    REMOTE_MODEL_CASES_SUMMARY,
    REMOTE_MODEL_REGISTRATION,
} from "navigation/remoteModelRoutes";
import {
    IMAGE_CAPTURE,
    STUDY_IMAGE_CAPTURE,
    LESION_LOCATOR,
    MEDICAL_HISTORY,
    PATIENT_DETAILS,
    READY_FOR_ASSESSMENT,
    CLINIC_LOCATION,
    PATIENT_SEARCH,
    PATIENT_LOOK_UP,
    NEW_ASSESSMENT,
} from "navigation/routes";

export const FILTER_PARAMETERS = {
    FILTER: "filter",
    PATIENT: "patient",
    SEARCH: "search",
};

const FILTER_PARAMETERS_MAP = {
    assignment: "assignment",
    caseStatus: "caseStatus",
    dateFrom: "creationDate:gt",
    dateTo: "creationDate:lt",
    dermatologist: "assignment",
    id: "caseId",
    birth: "birth",
    kitDeliveryStatus: "kitDeliveryStatus",
    locationUuid: "locationUuid",
    managementOutcome: "recommendation.raw",
    organisation: "organisationUuid",
    overdue: "overdue",
    nonSkinCancer: "nonSkinCancer",
    remote: "remote",
    isCallbackNeeded: "isCallbackNeeded",
    trackOutNo: "kitTracking.trackOutNo",
    patient: "patient",
    lesions: "lesions",
    medicalHistoryCreatedBy: "patient.medicalHistoryCreatedBy",
};

export const PATIENT_PARAMETERS_MAP = {
    hospitalId: "hospitalNo",
    lastName: "surname",
    name: "name",
    nhs: "nhs",
    preauthorisationNumber: "preauthorisationNumber",
    memberNumber: "memberNumber",
    postCode: "postCode",
    birth: "birth",
};

const SEARCH_PARAMETERS_MAP = {
    location: "locationName",
    recommendation: "recommendation",
    createdByEmail: "createdByEmail.text",
};

const PARAMETER_PREFIX = {
    [FILTER_PARAMETERS.FILTER]: "&filter",
    [FILTER_PARAMETERS.PATIENT]: "&patientSearch",
    [FILTER_PARAMETERS.SEARCH]: "&search",
};

const PARAMETER_MAP = {
    [FILTER_PARAMETERS.FILTER]: FILTER_PARAMETERS_MAP,
    [FILTER_PARAMETERS.PATIENT]: PATIENT_PARAMETERS_MAP,
    [FILTER_PARAMETERS.SEARCH]: SEARCH_PARAMETERS_MAP,
};

export const getPatientData = (patientData: IPatientData[], parameterName: string) => {
    const defaultValue =
        parameterName === PATIENT_PARAMETERS_MAP.nhs || parameterName === PATIENT_PARAMETERS_MAP.hospitalId
            ? ""
            : DATA_IS_NOT_AVAILABLE_TEXT;
    const parameter = patientData.find(
        (patientDataItem: any) => patientDataItem.name.toLowerCase() === parameterName.toLowerCase()
    );
    const value = parameter ? parameter.textValue || parameter.dateValue : defaultValue;
    return value;
};

export function generateParameters(filters: any, type: string) {
    const parameterName = PARAMETER_PREFIX[type];
    const MAP = PARAMETER_MAP[type];
    const filterKeys = filters ? Object.keys(filters) : [];
    const filterKeysLength = filterKeys.length;
    const startString = filterKeysLength ? parameterName : "";

    const filter = filterKeys.reduce((acc, filterKey, index) => {
        const value = filters[filterKey];
        if (!isUndefined(value)) {
            const sign = index === 0 ? "=" : ";";
            const encodedValue = encodeURIComponent(value);

            return `${acc}${sign}${MAP[filterKey]}:${encodedValue}`;
        }

        return acc;
    }, startString);

    return filter;
}

export const isColumnAvailable = (columnName: CaseListColumns): boolean =>
    Object.values(CaseListColumns).includes(columnName as CaseListColumns);

export const getIsReferCaseCallbackRequired = (currentCase: ICase): boolean => {
    const { reviews, isCallbackNeeded } = currentCase;
    const isSingleReview = reviews?.length === 1;
    const referReview = isSingleReview && reviews.find((review) => review.refer);
    const isReferCaseCallbackReview = Boolean(referReview && isCallbackNeeded);

    return isReferCaseCallbackReview;
};

const isLesionWithAllImages = (lesionImages): boolean => {
    const REMOTE_FLOW_IMAGE_LIMIT = 3;
    const imageTypes = lesionImages?.filter((image) => !image.removed).map((image) => image.type);
    const hasAllRemoteImageTypes = [CaptureImageType.MACRO, CaptureImageType.DERMOSCOPIC].every((type) =>
        imageTypes?.includes(type)
    );
    return hasAllRemoteImageTypes && imageTypes.length === REMOTE_FLOW_IMAGE_LIMIT;
};

export const areRemoteLesionsCompleted = (lesions: ILesion[]): boolean => {
    if (!lesions) {
        return false;
    }
    const lesionWithoutAllImages = lesions?.find((lesion: ILesion) => !isLesionWithAllImages(lesion.images));
    const isLesionWithoutAllImages = Boolean(lesionWithoutAllImages);
    return !isLesionWithoutAllImages;
};

export const getCaseStep = (currentCase: ICase): RemoteTimeline | "" => {
    const { lesions, caseStatus, patient } = currentCase;
    const { medicalHistory } = patient || {};

    const isCaseCompleted = caseStatus === CaseStatus.CASE_COMPLETED;
    const isCaseClosed = caseStatus === CaseStatus.CASE_CLOSED;
    const isInReview = [CaseStatus.CASE_FOR_SA_REVIEW, CaseStatus.CASE_IN_SA_REVIEW].includes(caseStatus);
    const isReferCaseCallbackReview = getIsReferCaseCallbackRequired(currentCase);

    if (!patient) {
        return RemoteTimeline.PERSONAL_DETAILS;
    }

    if (isCaseClosed && !isReferCaseCallbackReview) {
        return RemoteTimeline.CASE_CLOSED;
    }

    if (isCaseCompleted && !isReferCaseCallbackReview) {
        return RemoteTimeline.REPORT_AVAILABLE;
    }

    if (isInReview || (isCaseCompleted && isReferCaseCallbackReview)) {
        return RemoteTimeline.REVIEW;
    }

    if (lesions) {
        if (areRemoteLesionsCompleted(lesions)) {
            return RemoteTimeline.SUMMARY;
        }
        return RemoteTimeline.LESION_IMAGES;
    }

    if (medicalHistory) {
        return RemoteTimeline.KIT;
    }

    if (!medicalHistory) {
        return RemoteTimeline.MEDICAL_HISTORY;
    }

    return "";
};

export const getLinkToAssessment = (assessment: IAssessmentBase, organisation: IOrganisation): string => {
    const {
        case: { caseStatus, homeInitiated, locationUuid, consentData },
        lesion,
        patient,
        study,
    } = assessment;
    const { locations } = organisation;

    if (homeInitiated && caseStatus === CaseStatus.CASE_CREATED) {
        if (consentData && consentConfigurationChanged(consentData, organisation)) {
            return NEW_ASSESSMENT;
        }
        return assessment.patient?.integrationInfo?.integrationName === PatientDetailsIntegration.PDS
            ? PATIENT_LOOK_UP
            : PATIENT_DETAILS;
    }

    if (!patient) {
        const patientDetailsIntegrationPage =
            organisation.patientDetailsIntegrationType === PatientDetailsIntegration.PDS
                ? PATIENT_LOOK_UP
                : PATIENT_SEARCH;
        return organisation.patientDetailsIntegrationType ? patientDetailsIntegrationPage : PATIENT_DETAILS;
    }
    if (patient && !patient.medicalHistory) {
        return MEDICAL_HISTORY;
    }

    if (doesOrganisationHaveLocations(locations) && !locationUuid) {
        return CLINIC_LOCATION;
    }
    if (!lesion) {
        return LESION_LOCATOR;
    }
    if (study) {
        return STUDY_IMAGE_CAPTURE;
    }
    const { result } = lesion;
    if (lesion && !result) {
        return IMAGE_CAPTURE;
    }
    if (lesion && result) {
        return READY_FOR_ASSESSMENT;
    }
    return "";
};

export const getLinkToPatientAssessment = (assessment: IAssessmentBase): string => {
    switch (getCaseStep(assessment.case)) {
        case RemoteTimeline.PERSONAL_DETAILS: {
            return REMOTE_MODEL_REGISTRATION;
        }

        case RemoteTimeline.REPORT_AVAILABLE:
        case RemoteTimeline.CASE_CLOSED: {
            return REMOTE_MODEL_CASES_REPORT;
        }

        case RemoteTimeline.REVIEW: {
            return REMOTE_MODEL_CASES_IN_REVIEW;
        }

        case RemoteTimeline.SUMMARY: {
            return REMOTE_MODEL_CASES_SUMMARY;
        }

        case RemoteTimeline.LESION_IMAGES: {
            return REMOTE_MODEL_CASES_LESION_IMAGES;
        }

        case RemoteTimeline.KIT: {
            return REMOTE_MODEL_CASES_KIT_DELIVERY;
        }

        case RemoteTimeline.MEDICAL_HISTORY: {
            return REMOTE_MODEL_CASES_MEDICAL_HISTORY;
        }

        default: {
            return "";
        }
    }
};

export const INITIAL_FILTERS = {
    activeSearchField: CaseListSearchFieldKeys.LAST_NAME,
    dateFrom: null,
    dateTo: null,
    dermatologist: "",
    hospitalId: "",
    id: "",
    lastName: "",
    location: "",
    birth: null,
    locationUuid: "",
    managementOutcome: "",
    nhs: "",
    organisation: "",
    recommendation: "",
    remoteTimeline: "",
    status: "",
    pathway: "",
    name: "",
    createdByEmail: "",
    preauthorisationNumber: "",
    memberNumber: "",
    postCode: "",
};

const INITIAL_CASE_DATA = {
    data: [],
    filters: INITIAL_FILTERS,
    isPending: true,
    total: 0,
};

export const INITIAL_CASE_LISTS = {
    all: INITIAL_CASE_DATA,
    forReview: INITIAL_CASE_DATA,
    inProgress: INITIAL_CASE_DATA,
    inReview: INITIAL_CASE_DATA,
    inSaReview: INITIAL_CASE_DATA,
    toClose: INITIAL_CASE_DATA,
    toReview: INITIAL_CASE_DATA,
    overdue: INITIAL_CASE_DATA,
    toDispatch: INITIAL_CASE_DATA,
    dispatched: INITIAL_CASE_DATA,
    delivered: INITIAL_CASE_DATA,
    callback: INITIAL_CASE_DATA,
    reviewed: INITIAL_CASE_DATA,
};
