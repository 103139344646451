import { FC } from "react";
import { Image } from "semantic-ui-react";

import "./StatusProgressColumn.scss";

interface IStatusProgressColumn {
    progressStatusObj: {
        format: boolean;
        content: boolean;
        activation: boolean;
    };
}

const StatusProgressColumn: FC<IStatusProgressColumn> = ({ progressStatusObj }) => {
    const getProgressStatusNumber = (num: number) => <div className="uncompleted-block">{num}</div>;
    return (
        <>
            <h3>Set up checklist</h3>
            {Object.keys(progressStatusObj).map((statusName, index) => (
                <div key={statusName}>
                    {progressStatusObj[statusName] ? (
                        <Image alt="check-icon" className="completed-block" src="/images/icons/icon-round-check.svg" />
                    ) : (
                        getProgressStatusNumber(index + 1)
                    )}
                    <h4 className="progress-status">{statusName}</h4>
                </div>
            ))}
        </>
    );
};

export default StatusProgressColumn;
