import { filterAndSortAllocations } from "helpers/allocations";
import getLesionExclusions from "helpers/exclusion";
import {
    getDefinedPersonalDataWithoutName,
    getNameSurname,
    sortPersonalDataByOrder,
} from "helpers/definedPersonalData";

import { LesionLocation, LesionLocationNsc } from "model/lesionLocation";
import AnalysisResult from "model/analysisResult";
import { AnalysisStatus, IImage, ILesion, InterfaceVersion, IConsentData } from "model/case";
import { GuidanceValue, IAllocation, ILocation, IOrganisation, QuantifierValue } from "model/organisation";
import { IQrCodeBody } from "model/qrCodeBody";
import { CaptureImageType } from "model/captureImageType";
import { IAssessment, ILesionImage, IScrollToError } from "model/assessment";

const MAX_QR_CODE_BODY_SIZE = 3200;

export const getLesionAssessmentStatus = (lesion: ILesion) => {
    const { result } = lesion;

    const exclusions = getLesionExclusions(lesion);
    if (exclusions && exclusions.length) {
        return `Not Assessed by AI - Exclusion: ${exclusions}`;
    }

    if (result && result.analysisStatus === AnalysisStatus.STATUS_PROCESSED) {
        if (result.analysisResult === AnalysisResult.PROCESSING_ANSWER_TYPE_IMAGE_QUALITY_FAILED_BLURRED) {
            return "Not Assessed by AI - Lesion too blurred";
        }
        if (result.analysisResult === AnalysisResult.PROCESSING_ANSWER_TYPE_IMAGE_QUALITY_FAILED_TOO_DARK) {
            return "Not Assessed by AI - Lesion too dark";
        }
        if (result.analysisResult === AnalysisResult.PROCESSING_ANSWER_TYPE_NO_LESION_IMAGE) {
            return "Not Assessed by AI - No lesion on image";
        }
        if (result.analysisResult === AnalysisResult.PROCESSING_ANSWER_TYPE_SUCCESSFUL_PROCESSING) {
            return "Assessed";
        }
    }
    return "Not Assessed by AI";
};

export const getLesionExclusionStatus = (lesion: ILesion) => {
    const exclusions = getLesionExclusions(lesion);
    if (exclusions && exclusions.length) {
        return `Not Assessed by AI - Exclusion: ${exclusions.join(", ")}`;
    }
    return "Not Assessed by AI";
};

const getLesionGuidances = (lesions: ILesion[]) => {
    const resultList: string[] = [];

    lesions.forEach((lesion) => {
        const dermResponse = lesion.result;
        if (dermResponse) {
            if (lesion.excluded) {
                resultList.push(GuidanceValue.EXCLUSION);
            } else if (
                !dermResponse ||
                dermResponse.error ||
                dermResponse.analysisStatus === AnalysisStatus.STATUS_ERROR
            ) {
                resultList.push(GuidanceValue.EXCEPTION);
            } else if (dermResponse.lesions && dermResponse.lesions.length > 0) {
                const priorityLesion = dermResponse.lesions.reduce((min, dermLesion) =>
                    dermLesion.priority < min.priority ? dermLesion : min
                );
                if (dermResponse.interfaceNumber === InterfaceVersion.VERSION_2 && priorityLesion.conditionGuidance) {
                    resultList.push(priorityLesion.conditionGuidance.plainText);
                } else if (priorityLesion.referralFlag) {
                    resultList.push(priorityLesion.referralFlag.plainText);
                }
            }
        }
    });

    return resultList;
};

export const getCaseAllocation = (
    lesions: ILesion[],
    organisation: IOrganisation,
    useUnconsentedAllocationConfig: boolean
): IAllocation => {
    const { allocationConfiguration } = organisation;
    if (allocationConfiguration) {
        const orderedConfig = filterAndSortAllocations(allocationConfiguration, !useUnconsentedAllocationConfig);
        const lesionGuidanceValues = getLesionGuidances(lesions);

        /**
         * October 2023: during the DERM 4.0 release we noticed that an empty lesionResults array results in the
         * Discharge configuration being used. This is due to the default return value of `.every` in the
         * `case Quantifier.ALL`
         *
         * OV2 will now handle empty lesionResults as an EXCEPTION
         */
        if (!lesionGuidanceValues || lesionGuidanceValues.length === 0) {
            const exceptionConfig = orderedConfig.find(
                (config: IAllocation) => config.guidanceValue === GuidanceValue.EXCEPTION
            );
            return exceptionConfig;
        }

        const currentConfig = orderedConfig.find((config) => {
            let configMatches: boolean;

            switch (config.quantifier) {
                case QuantifierValue.ALL: {
                    configMatches = lesionGuidanceValues.every((res) => res === config.guidanceValue);
                    break;
                }
                case QuantifierValue.ANY: {
                    configMatches = lesionGuidanceValues.some((res) => res === config.guidanceValue);
                    break;
                }
                default: {
                    configMatches = false;
                }
            }

            if (configMatches) {
                return config;
            }
            return undefined;
        });

        if (currentConfig) {
            return currentConfig;
        }

        orderedConfig.some((config) => lesionGuidanceValues.includes(config.guidanceValue));
    }

    return null;
};

export const isCaseImmediateResult = (
    lesions: ILesion[],
    organisation: IOrganisation,
    useUnconsentedAllocationConfig: boolean
) => {
    const allocationConfig = getCaseAllocation(lesions, organisation, useUnconsentedAllocationConfig);

    if (allocationConfig) {
        return allocationConfig.immediateResults;
    }
    return false;
};

export const scrollToError = ({ errorUuid, currentQuestionUuid }: IScrollToError) => {
    const VALIDATION_ERROR_HEIGHT = 65;
    const MAIN_SEGMENT_PADDING_TOP = 24;
    const scrollTargetElement: HTMLElement = document.getElementById(`${errorUuid}.row`);
    const scrollTargetOffsetTop = scrollTargetElement?.getBoundingClientRect()?.top;
    const currentQuestionFormElement: HTMLElement = document.getElementById(`${currentQuestionUuid}.form`);
    const currentQuestionErrorOffsetTop = currentQuestionFormElement?.getBoundingClientRect()?.top;

    if (!currentQuestionFormElement || currentQuestionErrorOffsetTop > scrollTargetOffsetTop) {
        document.getElementById(`${errorUuid}.row`)?.scrollIntoView({ behavior: "smooth" });
    } else if (currentQuestionErrorOffsetTop < scrollTargetElement?.offsetTop) {
        const { offsetTop } = scrollTargetElement;
        const scrollToCompensator = currentQuestionFormElement?.className.includes("ui error form")
            ? VALIDATION_ERROR_HEIGHT + MAIN_SEGMENT_PADDING_TOP
            : -MAIN_SEGMENT_PADDING_TOP;
        const top = offsetTop - scrollToCompensator;

        document.getElementById("mainSegment")?.scrollTo({ behavior: "smooth", top });
    } else {
        document.getElementById(`${errorUuid}.row`)?.scrollIntoView({ behavior: "smooth" });
    }
};

export const getBodyForQrCode = (refreshToken: string, assessment: IAssessment) => {
    const { patient, lesion, case: currentCase, nonSkinCancer, currentLesion } = assessment;
    const { uuid: patientUuid } = patient;
    const { uuid: caseUuid } = currentCase;

    const { uuid: lesionUuid } = lesion;

    const qrCodeBody: IQrCodeBody = {
        caseUuid,
        deviceUuid: "",
        lesionNumber: currentLesion + 1,
        lesionUuid,
        patientUuid,
        onlyContext: nonSkinCancer,
        refreshToken,
        env: process.env.REACT_APP_ENVIRONMENT,
    };
    if (JSON.stringify(qrCodeBody).length > MAX_QR_CODE_BODY_SIZE) {
        qrCodeBody.authToken = "";
    }
    return JSON.stringify(qrCodeBody);
};

export const getLegacyBodyForQrCode = (
    authToken: string,
    refreshToken: string,
    assessment: IAssessment,
    organisation: IOrganisation
) => {
    const { patient, lesion, case: currentCase, nonSkinCancer, currentLesion } = assessment;
    const { patientData, uuid: patientUuid } = patient;
    const { caseId, uuid: caseUuid } = currentCase;
    const { definedPersonalData } = organisation;

    const patientNameSurname = getNameSurname(patientData);
    const patientNameString = patientNameSurname || patientData[0].textValue;

    const personalDataDefinitionWithoutName = sortPersonalDataByOrder(
        getDefinedPersonalDataWithoutName(definedPersonalData.filter((data) => !data.removed))
    );

    const definitionCustomProperty = personalDataDefinitionWithoutName[0];
    const customPropertyName = definitionCustomProperty.displayName;
    const customProperty = patientData.find((data) => data.name === definitionCustomProperty.propertyName);

    const { bodyPart: lesionBodyPart, description: lesionDescription, uuid: lesionUuid } = lesion;
    const bodyPart = nonSkinCancer ? LesionLocationNsc[lesionBodyPart] : LesionLocation[lesionBodyPart];

    const qrCodeBody: IQrCodeBody = {
        assessmentDetails: {
            bodyPart,
            customPropertyName: `${customPropertyName}`,
            customPropertyValue: `${customProperty?.textValue}`,
            lesionDescription,
            patient: patientNameString,
        },
        env: process.env.REACT_APP_ENVIRONMENT,
        authToken,
        caseId,
        caseUuid,
        deviceUuid: "",
        lesionNumber: currentLesion + 1,
        lesionUuid,
        patientUuid,
        onlyContext: nonSkinCancer,
        refreshToken,
    };
    if (JSON.stringify(qrCodeBody).length > MAX_QR_CODE_BODY_SIZE) {
        qrCodeBody.authToken = "";
    }
    return JSON.stringify(qrCodeBody);
};

export const doesLesionContainNeededImages = (lesionImages: ILesionImage[]) => {
    if (!lesionImages) {
        return false;
    }
    const containsMacroImage = lesionImages.find((image) => image.type === CaptureImageType.MACRO);
    const containsDermImage = lesionImages.find((image) => image.type === CaptureImageType.DERMOSCOPIC);
    return containsMacroImage && containsDermImage;
};

export const containsImagesToRetake = (images: IImage[]): boolean =>
    images.some((image) => !image.removed && image.retake);

export const containsRetakenImages = (images: IImage[]): boolean =>
    images.some((image) => image.removed && image.retake);

export const doesOrganisationHaveLocations = (locations: ILocation[]): boolean =>
    Boolean(locations?.find((location) => !location.removed));

export const consentConfigurationChanged = (consentData: IConsentData, organisationData: IOrganisation): boolean =>
    consentData?.consentDefinitionCreationDate
        ? consentData.consentDefinitionCreationDate !== organisationData.consentCreationDate
        : false;
