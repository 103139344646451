import { FC } from "react";
import SectionWrapper from "./SectionWrapper";

const MainColumn: FC = () => (
    <>
        <p>
            Need help setting up the outcome letters for this organisation? <br />
            To familiarise yourself with how the outcome letters work and how to set it up you can check the{" "}
            <a href="#!">cheatsheet</a>.
        </p>

        {/* SECTION 1: FORMAT */}
        <SectionWrapper />
    </>
);

export default MainColumn;
