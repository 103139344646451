import { FC, FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckboxProps, Container, Form, Image, InputOnChangeData, Message } from "semantic-ui-react";
import { assessmentActions } from "redux/actions";
import { getTranslatorData } from "redux/selectors/assessment";

import useAutoLogout from "hooks/useAutoLogout";

import CustomMessage, { CustomMessageType } from "components/templates/CustomMessage";
import CustomButton from "components/templates/Button";

import { ITranslatorData, TranslatorType } from "model/case";
import { IErrorResponse } from "model/errors";

import * as caseService from "services/caseService";

import withModal from "HOC/withModal";

interface ITranslatorDetailsEdit {
    caseUuid: string;
    closeDetails: () => void;
}

const MAX_INPUT_LENGTH = 50;
const LIMIT_EXCEEDED_ERROR_MESSAGE = `Maximum length of this field is ${MAX_INPUT_LENGTH} characters`;

const TranslatorDetailsEdit: FC<ITranslatorDetailsEdit> = ({ caseUuid, closeDetails }) => {
    useAutoLogout();
    let translatorData = useSelector(getTranslatorData);
    if (translatorData?.removed) {
        translatorData = undefined;
    }
    const dispatch = useDispatch();
    const [translatorType, setTranslatorType] = useState<TranslatorType | null>(
        translatorData ? translatorData.translatorType : null
    );
    const [languageProfessional, setLanguageProfessional] = useState(
        translatorData?.translatorType === TranslatorType.PROFESSIONAL ? translatorData.language : ""
    );
    const [languageOther, setLanguageOther] = useState(
        translatorData?.translatorType === TranslatorType.OTHER ? translatorData.language : ""
    );
    const [translatorId, setTranslatorId] = useState(translatorData ? translatorData.translatorId : "");
    const [translatorFullName, setTranslatorFullName] = useState(
        translatorData ? translatorData.translatorFullName : ""
    );
    const [translatorRelation, setTranslatorRelation] = useState(
        translatorData ? translatorData.translatorRelation : ""
    );
    const [apiErrorResponse, setApiErrorResponse] = useState<IErrorResponse>(null);

    const saveDetails = async (): Promise<void> => {
        try {
            setApiErrorResponse(null);
            const newTranslatorData: ITranslatorData = {
                translatorType,
                translatorId: translatorType === TranslatorType.PROFESSIONAL ? translatorId.trim() : undefined,
                translatorFullName: translatorType === TranslatorType.OTHER ? translatorFullName.trim() : undefined,
                translatorRelation: translatorType === TranslatorType.OTHER ? translatorRelation.trim() : undefined,
                language:
                    translatorType === TranslatorType.PROFESSIONAL ? languageProfessional.trim() : languageOther.trim(),
            };
            const result = await caseService.setTranslator(caseUuid, newTranslatorData);
            dispatch(assessmentActions.setTranslatorData({ translatorData: result.data }));
        } catch (error) {
            setApiErrorResponse({
                message: "Setting translator details failed.",
            });
        }
        closeDetails();
    };

    const changeTranslatorType = (event: FormEvent<HTMLInputElement>, obj: CheckboxProps): void => {
        const { value } = obj;
        const typeValue: TranslatorType = TranslatorType[value];
        setTranslatorType(typeValue);
    };

    const onLanguageChange = (event: any, data: InputOnChangeData): void => {
        const { id, value } = data;
        if (id === "languageProfessional") {
            setLanguageProfessional(value);
        } else if (id === "languageOther") {
            setLanguageOther(value);
        }
    };

    const onTranslatorIdChange = (event: any, data: InputOnChangeData): void => {
        setTranslatorId(data.value);
    };

    const onTranslatorFullNameChange = (event: any, data: InputOnChangeData): void => {
        setTranslatorFullName(data.value);
    };

    const onTranslatorRelationChange = (event: any, data: InputOnChangeData): void => {
        setTranslatorRelation(data.value);
    };

    const professionalTranslatorCharacterLimitExceeded =
        translatorType === TranslatorType.PROFESSIONAL &&
        (translatorId?.length > MAX_INPUT_LENGTH || languageProfessional?.length > MAX_INPUT_LENGTH);

    const otherTranslatorCharacterLimitExceeded =
        translatorType === TranslatorType.OTHER &&
        (translatorFullName?.length > MAX_INPUT_LENGTH ||
            translatorRelation?.length > MAX_INPUT_LENGTH ||
            languageOther?.length > MAX_INPUT_LENGTH);

    const invalidTranslatorData =
        !translatorType ||
        (translatorType === TranslatorType.PROFESSIONAL &&
            !translatorId?.trim().length &&
            !languageProfessional?.trim().length) ||
        (translatorType === TranslatorType.OTHER &&
            !translatorFullName?.trim().length &&
            !translatorRelation?.trim().length &&
            !languageOther?.trim().length) ||
        professionalTranslatorCharacterLimitExceeded ||
        otherTranslatorCharacterLimitExceeded;

    return (
        <Container className="ui segment wizzard-container">
            <Image className="translator-details-edit-icon" src="/images/icons/icon-person-add.svg" />
            <h3 className="translator-details-edit-title">Add translator details</h3>
            <div className="translator-details-edit">
                <Form className="margin-bottom-2">
                    <Form.Radio
                        label="Professional translator provided by the Trust (eg. language line, other professional translation services)"
                        name="translatorType"
                        value="PROFESSIONAL"
                        checked={translatorType === TranslatorType.PROFESSIONAL}
                        onChange={changeTranslatorType}
                    />
                    <Form.Radio
                        label="Other translator (eg. person accompanying the patient. Please specify)"
                        name="translatorType"
                        value="OTHER"
                        checked={translatorType === TranslatorType.OTHER}
                        onChange={changeTranslatorType}
                    />
                </Form>
                {translatorType === TranslatorType.PROFESSIONAL ? (
                    <div>
                        <Form className="margin-bottom-2">
                            <Form.Input
                                className={translatorId?.length > MAX_INPUT_LENGTH && "error"}
                                type="text"
                                value={translatorId}
                                label="Translator ID"
                                id="translatorId"
                                placeholder="eg. language line ID"
                                onChange={onTranslatorIdChange}
                            />
                        </Form>
                        <Form className="margin-bottom-2">
                            <Form.Input
                                className={languageProfessional?.length > MAX_INPUT_LENGTH && "error"}
                                type="text"
                                value={languageProfessional}
                                label="Language"
                                id="languageProfessional"
                                placeholder="Enter language"
                                onChange={onLanguageChange}
                            />
                        </Form>
                        {professionalTranslatorCharacterLimitExceeded && (
                            <Message error content={LIMIT_EXCEEDED_ERROR_MESSAGE} />
                        )}
                    </div>
                ) : null}
                {translatorType === TranslatorType.OTHER ? (
                    <div>
                        <Form className="margin-bottom-2">
                            <Form.Input
                                className={translatorFullName?.length > MAX_INPUT_LENGTH && "error"}
                                type="text"
                                value={translatorFullName}
                                label="Translator name"
                                id="translatorFullName"
                                placeholder="Enter translator full name"
                                onChange={onTranslatorFullNameChange}
                            />
                        </Form>
                        <Form className="margin-bottom-2">
                            <Form.Input
                                className={translatorRelation?.length > MAX_INPUT_LENGTH && "error"}
                                type="text"
                                value={translatorRelation}
                                label="Relation to the patient"
                                id="translatorRelation"
                                placeholder="Specify relation to the patient"
                                onChange={onTranslatorRelationChange}
                            />
                        </Form>
                        <Form className="margin-bottom-2">
                            <Form.Input
                                className={languageOther?.length > MAX_INPUT_LENGTH && "error"}
                                type="text"
                                value={languageOther}
                                label="Language"
                                id="languageOther"
                                placeholder="Enter language"
                                onChange={onLanguageChange}
                            />
                        </Form>
                        {otherTranslatorCharacterLimitExceeded && (
                            <Message error content={LIMIT_EXCEEDED_ERROR_MESSAGE} />
                        )}
                    </div>
                ) : null}
                {apiErrorResponse ? (
                    <CustomMessage type={CustomMessageType.ERROR} message={apiErrorResponse.message} />
                ) : null}
            </div>
            <div className="buttons-wrapper">
                <CustomButton type="button" variant="cancel" action={closeDetails} text="Cancel" />
                <CustomButton
                    type="button"
                    variant="filled"
                    action={saveDetails}
                    text="Save details"
                    disabled={invalidTranslatorData}
                />
            </div>
        </Container>
    );
};

export default withModal(TranslatorDetailsEdit);
