import { FC } from "react";
import { List, ListItem } from "semantic-ui-react";

import { ConsentGiverType, IConsentData, ITranslatorData, TranslatorType } from "model/case";
import { ReviewMode } from "model/caseViewMode";

interface IAdditionalAdministrativeDetails {
    consentData?: IConsentData;
    translatorData?: ITranslatorData;
    mode?: ReviewMode;
}

const AdditionalAdministrativeDetails: FC<IAdditionalAdministrativeDetails> = ({
    consentData,
    translatorData,
    mode,
}) => {
    const getConsentDetails = () =>
        consentData ? (
            <div>
                {consentData.consentGiverType === ConsentGiverType.PATIENT && (
                    <span>Consent was provided by the patient.</span>
                )}
                {consentData.consentGiverType === ConsentGiverType.LPA && (
                    <>
                        <span>Consent was provided by:</span>
                        <List bulleted>
                            <ListItem key="consentGiverFullName">
                                <span>Name: {consentData.consentGiverFullName}</span>
                            </ListItem>
                            <ListItem key="consentGiverRelation">
                                <span>Relation to the patient: Lasting Power of Attorney</span>
                            </ListItem>
                        </List>
                    </>
                )}
                {consentData.consentGiverType === ConsentGiverType.OTHER && (
                    <>
                        <span>
                            Please note that consent has not been provided by patient or an LPA for health and welfare.
                            Answers to consent statement have been provided by:
                        </span>
                        <List bulleted>
                            <ListItem key="consentGiverFullName">
                                <span>Name: {consentData.consentGiverFullName}</span>
                            </ListItem>
                            <ListItem key="consentGiverRelation">
                                <span>Relation to the patient: {consentData.consentGiverRelation}</span>
                            </ListItem>
                        </List>
                    </>
                )}
            </div>
        ) : null;

    const getTranslatorDetails = () => {
        const isProfessional = translatorData?.translatorType === TranslatorType.PROFESSIONAL ? "professional " : "";
        return (
            <div className="additional-administrative-details-content-item">
                {!translatorData ? (
                    <span>A translator was not involved.</span>
                ) : (
                    <span>
                        A {isProfessional}translator was involved during the appointment to help with consent or other
                        clinical information
                    </span>
                )}
                {translatorData?.translatorType === TranslatorType.PROFESSIONAL && (
                    <List bulleted>
                        <ListItem key="translatorId">
                            <span>
                                Translator ID:{" "}
                                {translatorData.translatorId ? translatorData.translatorId : "Not entered"}
                            </span>
                        </ListItem>
                        <ListItem key="language">
                            <span>Language: {translatorData.language ? translatorData.language : "Not entered"}</span>
                        </ListItem>
                    </List>
                )}
                {translatorData?.translatorType === TranslatorType.OTHER && (
                    <List bulleted>
                        <ListItem key="translatorFullName">
                            <span>
                                Translator name:{" "}
                                {translatorData.translatorFullName ? translatorData.translatorFullName : "Not entered"}
                            </span>
                        </ListItem>
                        <ListItem key="translatorFullName">
                            <span>
                                Relation to the patient:{" "}
                                {translatorData.translatorRelation ? translatorData.translatorRelation : "Not entered"}
                            </span>
                        </ListItem>
                        <ListItem key="language">
                            <span>Language: {translatorData.language ? translatorData.language : "Not entered"}</span>
                        </ListItem>
                    </List>
                )}
            </div>
        );
    };

    const consentDetails = getConsentDetails();
    const translatorDetails = getTranslatorDetails();
    const className =
        mode === ReviewMode.REVIEW
            ? "additional-administrative-details-review"
            : "additional-administrative-details-view";

    // Translator data management is independent from extended consent data, but it has been agreed to introduce them together and use consent data existence as flag for in flight cases.
    return consentData ? (
        <div>
            <div className={className}>
                <div className="additional-administrative-details-content">
                    <span>{consentDetails}</span>
                    <span>{translatorDetails}</span>
                </div>
            </div>
        </div>
    ) : null;
};

export default AdditionalAdministrativeDetails;
