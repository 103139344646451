import types from "redux/actions/types";

const INITIAL_STATE = {
    isModalConfirmationVisible: false,
    isModalEmailConfirmationVisible: false,
    isModalRedirectVisible: false,
    isPreathorisationNumberErrorModalVisible: false,
    isAccountExistInDifferentOrganisationVisible: false,
};

const registrationUIReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case types.SET_IS_MODAL_CONFIRMATION_VISIBLE:
            return {
                ...state,
                isModalConfirmationVisible: action.payload,
            };

        case types.SET_IS_MODAL_EMAIL_CONFIRMATION_VISIBLE:
            return {
                ...state,
                isModalEmailConfirmationVisible: action.payload,
            };

        case types.SET_IS_MODAL_REDIRECT_VISIBLE:
            return {
                ...state,
                isModalRedirectVisible: action.payload,
            };
        case types.SET_IS_PREAUTHORISATION_NUMBER_ERROR_MODAL_VISIBLE:
            return {
                ...state,
                isPreathorisationNumberErrorModalVisible: action.payload,
            };
        case types.SET_IS_ACCOUNT_EXIST_IN_DIFFERENT_ORGANISATION_VISIBLE:
            return {
                ...state,
                isAccountExistInDifferentOrganisationVisible: action.payload,
            };

        default:
            return state;
    }
};

export default registrationUIReducer;
