import React, { Component } from "react";
import { Table } from "semantic-ui-react";

import { ILesion } from "model/case";
import { CaseViewMode } from "model/caseViewMode";
import { LesionClassification } from "model/lesionClassification";
import PresentingComplaint from "model/presentingComplaint";
import { DATA_IS_NOT_AVAILABLE_TEXT } from "model/common";

import { getLesionAssessmentStatus, getLesionExclusionStatus } from "helpers/assessment";
import { getLesionManagementOutcome, getLocation } from "helpers/caseDescription";
import getLesionExclusions from "helpers/exclusion";

import "scss/Table.scss";
import DermLabelling from "../DermLabelling";

interface ILesionRowProps {
    lesion: ILesion;
    lesionNumber: number;
    mode: CaseViewMode;
    nonSkinCancer?: boolean;
}

class CaseSummaryRow extends Component<ILesionRowProps> {
    private getLesionName() {
        const { lesionNumber, lesion, nonSkinCancer } = this.props;
        if (nonSkinCancer) {
            return (
                <>
                    {getLocation(lesion)}
                    <br />
                    <br />
                    {CaseSummaryRow.getPresentingComplaint(lesion)}
                </>
            );
        }
        return `${lesionNumber + 1}. ${getLocation(lesion)}`;
    }

    private static getPresentingComplaint(lesion: ILesion) {
        return `${PresentingComplaint[lesion.presentingComplaint]} (${lesion.complaintDescription})`;
    }

    private getAssessedBy(): string | JSX.Element {
        const { lesion, nonSkinCancer } = this.props;
        const { reviews } = lesion;
        const reviewerDiagnosis = reviews && reviews[reviews.length - 1];
        if (reviewerDiagnosis) {
            return reviewerDiagnosis.createdByName;
        }
        if (nonSkinCancer) {
            return DATA_IS_NOT_AVAILABLE_TEXT;
        }
        return <DermLabelling dermResult={lesion.result} />;
    }

    private getManagementOutcome() {
        const { lesion } = this.props;
        const { reviews } = lesion;
        const reviewerDiagnosis = reviews && reviews[reviews.length - 1];
        if (reviewerDiagnosis) {
            const { reviewManagement, managementAdditionalText } = reviewerDiagnosis;

            return (
                LesionClassification[reviewManagement] ||
                `${reviewManagement}${managementAdditionalText ? ` (${managementAdditionalText})` : ""}`
            );
        }
        if (getLesionExclusions(lesion).length || !lesion.result) {
            return DATA_IS_NOT_AVAILABLE_TEXT;
        }
        return getLesionManagementOutcome(lesion, true);
    }

    private getDiagnosis() {
        const { lesion, nonSkinCancer } = this.props;
        const reviewerDiagnosis = lesion.reviews && lesion.reviews[lesion.reviews.length - 1];
        if (reviewerDiagnosis) {
            const { reviewDiagnosis, diagnosisAdditionalText } = reviewerDiagnosis;

            return (
                LesionClassification[reviewerDiagnosis.reviewDiagnosis] ||
                `${reviewDiagnosis}${diagnosisAdditionalText ? ` (${diagnosisAdditionalText})` : ""}`
            );
        }

        if (nonSkinCancer) {
            return DATA_IS_NOT_AVAILABLE_TEXT;
        }

        if (getLesionExclusions(lesion).length) {
            return getLesionExclusionStatus(lesion);
        }

        return (
            lesion.result?.lesions?.map((lesionResult) => lesionResult.suspectedDiagnosis).join(", ") ||
            getLesionAssessmentStatus(lesion) ||
            DATA_IS_NOT_AVAILABLE_TEXT
        );
    }

    public render() {
        return (
            <>
                <Table.Row className="item">
                    <Table.Cell>
                        <p>{this.getLesionName()}</p>
                    </Table.Cell>
                    <Table.Cell>{this.getAssessedBy()}</Table.Cell>
                    <Table.Cell>{this.getDiagnosis()}</Table.Cell>
                    <Table.Cell>{this.getManagementOutcome()}</Table.Cell>
                </Table.Row>
            </>
        );
    }
}

export default CaseSummaryRow;
