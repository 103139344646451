import React, { FC } from "react";
import { DialogContent } from "@material-ui/core";

import ModalDialog from "components/templates/dialog/ModalDialog";

import withModal from "HOC/withModal";

interface IEmailExistForDifferentOrganisationErrorModal {
    show: boolean;
    onBackClicked: () => void;
}

const EmailExistForDifferentOrganisationErrorModal: FC<IEmailExistForDifferentOrganisationErrorModal> = ({
    show,
    onBackClicked,
}) => (
    <ModalDialog
        title="Looks like you already have an account"
        open={show}
        iconSrc="/images/icons/icon-info-filled.svg"
        onClose={onBackClicked}
        maxWidth="sm"
        disableBackdropClick
        buttons={[
            {
                onClick: onBackClicked,
                text: "Back",
                colour: "primary",
            },
        ]}
    >
        <DialogContent>
            <p>
                It seems this email address is already associated with an account under a different insurance provider.
                To continue, please use another email address. If you need assistance, our support team is here to help:{" "}
                <br />
                support@skinanalytics.co.uk
            </p>
        </DialogContent>
    </ModalDialog>
);

export default withModal(EmailExistForDifferentOrganisationErrorModal);
