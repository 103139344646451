import { IAdminNextStepQuestion } from "model/administrativeNextSteps";
import AnalysisResult from "model/analysisResult";
import { IIntegration, IMedicalHistory } from "model/assessment";
import { BodyLocations } from "model/bodyLocations";
import { CaseStatus } from "model/caseStatus";
import Confidence from "model/confidence";
import { LesionClassification } from "model/lesionClassification";
import { ILesionExclusion } from "model/organisation";
import Pathway from "model/pathway";
import { IAuditData } from "model/auditData";
import IClickOrigin from "model/clickOrigin";
import { FitzpatrickAnswersType } from "model/fixedQuestion";
import PresentingComplaint from "model/presentingComplaint";
import { KitDeliveryStatuses, IKitTracking, CallbackOutcomesValues } from "model/remoteModel";

export interface IAssignment {
    assigneeUuid: string;
    assigneeName: string;
    uuid?: string;
}

export interface INotification {
    createdBy: string;
    userUuid: string;
    notificationType: string;
    creationDate: string;
    uuid: string;
    caseUuid: string;
    email: string;
    organisationUuid: string;
    createdByName: string;
}

export interface ICase {
    caseId: string;
    caseStatus: CaseStatus;
    consentDate: string;
    secondaryConsentDate: string;
    automatedDecisionConsentDate: string;
    createdBy: string;
    creationDate: string;
    lastModificationDate: string;
    lastModifiedBy: string;
    uuid: string;
    organisationName: string;
    patient: IPatient;
    patientUuid: string;
    lesions: ILesion[];
    locationName: string;
    report: IReport;
    reportPath: string;
    recommendation: string;
    locationUuid?: string;
    reviews: IReview[];
    assignmentDetails: IAssignment;
    assignment: string;
    patientNotes: IReviewNote[];
    clinicalNotes: IReviewNote[];
    organisationUuid: string;
    aiRecommendation: string;
    abandonReason: string;
    generatedReport: boolean;
    createdByName: string;
    notifications: INotification[];
    createdByEmail: string;
    organisationEmail: string;
    overdue: boolean;
    overdueCreationDate: string;
    caseStatusModificationDate: string;
    sasLink: string;
    adminNotes: IReviewNote[];
    nonSkinCancer?: boolean;
    feedback?: IFeedback;
    remote?: boolean;
    kitDeliveryStatus?: KitDeliveryStatuses;
    kitDeliveryStatusLastModificationDate?: string;
    kitTracking: IKitTracking;
    isCallbackNeeded: boolean;
    callbackOutcome: ICallbackOutcome[];
    studyUuid?: string;
    homeInitiated?: boolean;
    administrativeNextSteps?: IAdminNextStepQuestion[];
    audit: ICaseAudit;
    consentData?: IConsentData;
    translatorData?: ITranslatorDataWithRemoved;
    chaperoneData?: IChaperoneDataWithRemoved;
}

export interface IFeedback {
    feedback: string;
    lastModificationDate: string;
    createdBy: string;
    rating: number;
    uuid: string;
    caseUuid: string;
    creationDate: string;
}

export interface IReviewNote {
    removed: boolean;
    note: string;
    uuid: string;
    createdByName: string;
    type: string;
    lastModificationDate: string;
    creatorOrganisationName: string;
    organisationUuid: string;
    createdBy?: string;
    originalCreationDate?: string;
    creationDate?: string;
    supersedes?: string;
    deletedDate?: string;
    noteCreatedDate?: string;
}

export interface IReview {
    caseUuid: string;
    lesionUuid: string;
    createdBy: string;
    createdByName: string;
    creatorOrganisationName: string;
    creationDate: string;
    reviewDiagnosis: string;
    reviewDiagnosisUuid: string;
    reviewManagement: string;
    reviewManagementUuid: string;
    safetyNetReview: boolean;
    refer: boolean;
    uuid: string;
    removed: boolean;
    managementAdditionalText: string;
    diagnosisAdditionalText: string;
    escalationPlan?: string;
    escalationPlanUuid?: string;
    escalationPlanAdditionalText?: string;
    organisationUuid: string;
    callbackReview: boolean;
}

export interface IReport {
    path: string;
    createdBy: string;
    creatopmDate: string;
    uuid: string;
    status: ReportStatus;
}

export enum ReportStatus {
    FAILED = "FAILED",
    GENERATED = "GENERATED",
}

export interface ISkinToneAnswer {
    uuid: string;
    caseUuid: string;
    answerId: number;
    questionUuid: string;
    patientUuid: string;
    organisationUuid: string;
    skinToneClassification: FitzpatrickAnswersType;
    createdBy: string;
    creationDate: Date;
    lastModificationDate: Date;
    lastModifiedBy: string;
    clickOrigin: IClickOrigin;
    removed: boolean;
}

export interface IPatient {
    lastModificationDate: string;
    patientData: IPatientData[];
    createdBy: string;
    createdByName: string;
    creationDate: string;
    uuid: string;
    caseUuid: string;
    medicalHistory: IMedicalHistory[];
    patientNotes?: IPatientNote[];
    contactPreferences: IContactPreferences;
    integrationInfo?: IIntegration;
    skinToneClassification: ISkinToneAnswer[];
    medicalHistoryLastModifiedByName?: string;
}

export interface IContactPreferences {
    weekend?: boolean;
    partsOfDay: ContactPartsOfDay[];
}

export enum ContactPartsOfDay {
    MORNING = "MORNING",
    AFTERNOON = "AFTERNOON",
    EVENING = "EVENING",
}

export interface IPatientNote extends IAuditData {
    uuid: string;
    patientUuid: string;
    note: string;
    patientDataUpdates?: IPatientDataUpdate[];
    removed: boolean;
    createdByName: string;
    creatorOrganisationName: string;
}

export interface IPatientDataUpdate {
    fieldName: string;
    type: string;
    patientNoteUuid: string;
    previousValue: string;
    value: string;
}

export interface IPatientData {
    textValue?: string;
    dateValue?: string;
    numberValue?: number;
    name: string;
    type: string;
    removed?: boolean;
    options?: string;
    booleanValue?: boolean;
}

export interface ILesion {
    description: string;
    lastModificationDate: string;
    createdBy: string;
    createdByName: string;
    patientUuid: string;
    excluded: boolean;
    exclusions: ILesionExclusion[];
    lastModifiedBy: string;
    creationDate: string;
    uuid: string;
    caseUuid: string;
    bodyPart: BodyLocations;
    images: IImage[];
    diagnosis: IDiagnosis;
    result: IResult;
    lesionHistory: IMedicalHistory[];
    reviews: IReview[];
    presentingComplaint?: PresentingComplaint;
    complaintDescription?: string;
}

export interface IDiagnosis {
    studyUuid: string;
    lastModificationDate: string;
    createdBy: string;
    confidence: Confidence;
    lastModifiedBy: string;
    lesionUuid: string;
    diagnosis: LesionClassification;
    pathway: Pathway;
    creationDate: string;
    uuid: string;
    caseUuid: string;
}

export interface IImage {
    imageName: string;
    patientUuid: string;
    lesionUuid: string;
    userUuid: string;
    creationDate: string;
    imageType: string;
    uuid: string;
    caseUuid: string;
    sendToDerm: false;
    status: string;
    type: string;
    retake?: boolean;
    retakeReasons?: BadImgReason[];
    retakeReasonOther?: string;
    removed?: boolean;
    createdByName: string;
    creatorOrganisationName: string;
}

export interface ISelectedImage {
    checked: boolean;
    uuid: string;
    reasons: BadImgReason[];
    optionalNotes: string;
}

export enum BadImgReason {
    BLURRY = "BLURRY",
    TOO_DARK = "TOO_DARK",
    NOT_IN_FOCUS = "NOT_IN_FOCUS",
    NOT_PRESSED = "NOT_PRESSED",
    LOCATION_MISMATCH = "LOCATION_MISMATCH",
    OTHER = "OTHER",
}

export enum BadImgReasonText {
    BLURRY = "The image was blurry",
    TOO_DARK = "The image was too dark",
    NOT_IN_FOCUS = "The lesion was not in focus",
    NOT_PRESSED = "Dermoscope was not pressed against the skin",
    LOCATION_MISMATCH = "The lesion in the image doesn't match lesion location listed",
    OTHER = "Other",
}

export enum RetakeImagesError {
    MISSING_REASON = "MISSING_REASON",
    NO_IMAGES_SELECTED = "NO_IMAGES_SELECTED",
    NO_SERVER_RESPONSE = "NO_SERVER_RESPONSE",
}

export interface IRetakeImageReq {
    uuid: string;
    retakeReasons: BadImgReason[];
    retakeReasonOther?: string;
}

export interface IRetakeImagesReq {
    retakeImages: IRetakeImageReq[];
}

export interface IBaseResult {
    dermProductNumber: string;
    medicalLabellingGraphic?: string;
}

export interface IResult extends IBaseResult {
    settings: [];
    lesions: IResultLesion[];
    requestingOrganisation: string;
    analysisResult: AnalysisResult;
    assessedByDermVersion: string;
    imageHash: string;
    clientInternalId: string;
    interfaceNumber: InterfaceVersion;
    reportDate: string;
    caseId: number;
    lesionCount: number;
    udi: string;
    analysisStatus: AnalysisStatus;
    requestingSystem: string;
    error?: string;
}

export enum AnalysisStatus {
    STATUS_PROCESSED = "STATUS_PROCESSED",
    STATUS_ERROR = "STATUS_ERROR",
}

export interface IResultLesion {
    classification: LesionClassification;
    suspectedDiagnosis: string;
    suspectedDiagnosisICD10Code: string;
    suspectedDiagnosisICD10Term: string;
    suspectedDiagnosisSnomedCTCode: string;
    suspectedDiagnosisSnomedCTTerm: string;
    referralRecommendation: IRichValue;
    referralRecommendationGuidance: IRichValue;
    interpretationGuidance: IRichValue;
    conditionGuidance?: IRichValue;
    referralFlag?: IRichValue;
    referralSnomedCode: string;
    priority: number;
}
export interface IRichValue {
    plainText: string;
    richText: string;
}

export enum InterfaceVersion {
    VERSION_2 = "2.0",
    VERSION_3 = "3.0",
}

export interface ICallbackOutcome {
    caseUuid: string;
    createdBy: string;
    creationDate: string;
    lastModificationDate: string;
    lastModifiedByName: string;
    lastModifiedBy: string;
    outcome: CallbackOutcomesValues;
    uuid: string;
}

export interface ICaseAudit {
    uuid: string;
    caseUuid: string;
    auditSetUuid: string;
    addedToAuditSetDate: Date;
    creationDate: Date;
    createdBy: string;
    lastModificationDate: Date;
    lastModifiedBy: string;
    sentForAuditDate?: Date;
}

export enum ConsentGiverType {
    PATIENT = "PATIENT",
    LPA = "LPA", // Lasting Power of Attorney
    OTHER = "OTHER",
}

export interface IConsentData {
    assessmentConsentText: string;
    assessmentConsentAnswer: string;
    assessmentConsentDate: Date;
    additionalUseOfDataConsentText?: string;
    additionalUseOfDataPrimaryConsentText?: string;
    additionalUseOfDataPrimaryConsentAnswer?: string;
    additionalUseOfDataPrimaryConsentDate?: Date;
    additionalUseOfDataSecondaryConsentText?: string;
    additionalUseOfDataSecondaryConsentAnswer?: string;
    additionalUseOfDataSecondaryConsentDate?: Date;
    automatedDecisionConsentText?: string;
    automatedDecisionConsentAnswer?: string;
    automatedDecisionConsentDate?: Date;
    consentGiverType: ConsentGiverType;
    consentGiverFullName?: string;
    consentGiverRelation?: string;
    consentDefinitionCreationDate?: Date;
}

export enum TranslatorType {
    PROFESSIONAL = "PROFESSIONAL",
    OTHER = "OTHER",
}

export interface ITranslatorData {
    translatorType: TranslatorType;
    language: string;
    translatorId?: string;
    translatorFullName?: string;
    translatorRelation?: string;
}

export interface ITranslatorDataWithRemoved extends ITranslatorData {
    removed: boolean;
}

export interface IChaperoneData {
    chaperoneFullName: string;
}

export interface IChaperoneDataWithRemoved extends IChaperoneData {
    removed: boolean;
}
