export enum AdministrationPages {
    REPORTS = "reports",
    CASE_REPORT = "case-report",
    USER_MANAGEMENT = "user-management",
    ORGANISATION_MANAGEMENT = "organisation-management",
    STUDY_MANAGEMENT = "study-management",
    REVIEW_MANAGEMENT = "review-management",
}

export enum UserManagementSubpages {
    USER_DETAILS = "user-details",
    CREATE_USER = "create-user",
}

export enum OrganisationManagementSubpages {
    ORGANISATION_DETAILS = "organisation-details",
    CREATE_ORGANISATION = "create-organisation",
    CREATE_SUBORGANISATION = "create-suborganisation",
}

export enum StudyManagmentSubpages {
    STUDY_DETAILS = "study-details",
    CREATE_STUDY = "create-study",
    CREATE_DEVICE = "create-device",
}

export enum ReviewManagementSubpages {
    REVIEV_DETAILS = "review-details",
    CREATE_DIAGNOSIS = "create-diagnosis",
}

export enum OrganisationManagementTabs {
    QUESTIONS = "questions",
    PERSONAL_DATA = "personal-data",
    EXCLUSIONS = "exclusions",
    REVIEW_SETTINGS = "review",
    TEMPLATES = "templates",
    ALLOCATIONS = "allocations",
    LOCATIONS = "locations",
    NOTIFICATIONS = "notifications",
}

export enum OrganisationMenuItems {
    ORGANISATION_DETAILS = "ORGANISATION_DETAILS",
    SUBORGANISATIONS = "SUBORGANISATIONS",
    CASE_ALLOCATION = "CASE_ALLOCATION",
    LOCATIONS = "LOCATIONS",
    NOTIFICATIONS = "NOTIFICATIONS",
    TEMPLATES = "TEMPLATES",
    CONSENT = "CONSENT",
    PERSONAL_DATA = "PERSONAL_DATA",
    MEDICAL_HISTORY = "MEDICAL_HISTORY",
    NSC_MEDICAL_HISTORY = "NSC_MEDICAL_HISTORY",
    LESION_HISTORY = "LESION_HISTORY",
    SKIN_COMPLAINT_HISTORY = "SKIN_COMPLAINT_HISTORY",
    BIOPSY = "BIOPSY",
    EXCLUSIONS = "EXCLUSIONS",
    REVIEW_SETTINGS = "REVIEW_SETTINGS",
    NSC_REVIEW_SETTINGS = "NSC_REVIEW_SETTINGS",
    OVERDUE_SETTINGS = "OVERDUE_SETTINGS",
    LANDING_PAGE_DATA = "LANDING_PAGE_DATA",
    OUTCOME_LETTERS = "OUTCOME_LETTERS",
}

export enum ReviewEntities {
    DIAGNOSIS = "DIAGNOSIS",
    MANAGEMENT_OUTCOME = "MANAGEMENT_OUTCOME",
}

export enum OrganisationPropertyLabels {
    ALLOW_HOME_INITIATED_CASES_LABEL = "At home patient questionnaire",
    LESION_LIMIT = "Lesion Limit",
    MINIMUM_AGE = "Minimum Age",
    NON_SKIN_CANCER_ALLOWED_LABEL = "Other skin conditions pathway",
    MFA = "Multi-factor authentication",
    OPTIONAL_CALLBACK_LABEL = "GP callback for referred cases",
}

export enum OrganisationPropertyHelpText {
    MFA = `Multi-factor authentication adds an extra layer of security by requiring users to verify their identity
        through email and SMS verification. This helps protect sensitive patient data and prevent unauthorized access`,
    OPTIONAL_CALLBACK = `When callback field is set to "Required", referred patients will always be called back by a 
        Skin Analytics clinician before they can access their results. When this field is set to "Optional", 
        all patients will be able to access their results after dermatologist review and the callback functionality 
        is offered as an optional service.`,
}

export enum DermatologistAvailabilityEntities {
    AVAILABLE_FOR_CASE_ALLOCATION = "AVAILABLE_FOR_CASE_ALLOCATION",
    NOT_AVAILABLE_FOR_CASE_ALLOCATION = "NOT_AVAILABLE_FOR_CASE_ALLOCATION",
}

export enum DermatologistAvailabilityLabel {
    AVAILABLE_FOR_CASE_ALLOCATION = "Available for case allocation",
    NOT_AVAILABLE_FOR_CASE_ALLOCATION = "Not available for case allocation",
}

export enum DermatologistAvailabilityMapping {
    NOT_AVAILABLE_FOR_CASE_ALLOCATION = 0,
    AVAILABLE_FOR_CASE_ALLOCATION = 1,
}

// Some Ids use camelCase, because CreateOrganisationComponent uses the id to update the state
export enum OrganisationPropertyIds {
    LESION_LIMIT = "lesionsLimitInput",
    MINIMUM_AGE = "minimumAgeInput",
    ORGANISATION_MFA_EDIT = "organisation-mfa-edit",
    ORGANISATION_MFA_CREATE = "organisation-mfa-create",
}
