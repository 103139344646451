import React, { FC } from "react";
import { Image, Header } from "semantic-ui-react";

import CustomButton from "components/templates/Button";

import withModal from "HOC/withModal";

import { ModalIconAltTexts } from "model/modal";

interface IConsentConfigChangedModal {
    onClose: () => void;
}

const ConsentConfigChangedModal: FC<IConsentConfigChangedModal> = ({ onClose }) => (
    <div className="remote-model__modal">
        <Image alt={ModalIconAltTexts.INFO} src="/images/icons/icon-info-filled.svg" />
        <Header as="h2">Pathway consent updated</Header>
        <p>
            The consent configuration has been updated. To continue, the patient must review and confirm their consent
            choices to ensure their data is handled correctly.
        </p>
        <div className="buttons-wrapper">
            <CustomButton type="button" variant="filled" action={onClose} text="Got it" />
        </div>
    </div>
);

export default withModal(ConsentConfigChangedModal);
