import React, { Component } from "react";
import { Grid, Segment } from "semantic-ui-react";

import { ILesion, IResult } from "model/case";
import { CaseViewMode } from "model/caseViewMode";
import { DATA_IS_NOT_AVAILABLE_TEXT } from "model/common";
import { DermErrorResponse } from "model/errors";

import { getLesionAssessmentStatus } from "helpers/assessment";
import { getLesionManagementOutcome } from "helpers/caseDescription";
import { getDermError } from "helpers/derm";
import getLesionExclusions from "helpers/exclusion";
import { formatTimeWithHours } from "helpers/datetime";
import DermLabelling from "../DermLabelling";

interface ILesionAiAssessmentProps {
    lesion: ILesion;
    mode: CaseViewMode;
}

class LesionAIAssessment extends Component<ILesionAiAssessmentProps, any> {
    private getErrorInfo() {
        const { lesion } = this.props;
        const isExclusion = getLesionExclusions(lesion).length;

        const dermError = lesion.result?.error ? getDermError(lesion.result) : undefined;
        const analysisError =
            lesion.result?.analysisStatus === "STATUS_ERROR"
                ? LesionAIAssessment.getLesionErrorReason(lesion.result)
                : undefined;

        return (
            !isExclusion &&
            (analysisError || dermError) && (
                <Grid.Row>
                    <Grid.Column width={8}>
                        <p>Error details</p>
                        <Segment>{analysisError || dermError}</Segment>
                    </Grid.Column>
                </Grid.Row>
            )
        );
    }

    private getAssessmentData(): JSX.Element {
        const { lesion } = this.props;
        if (!lesion?.result?.reportDate) {
            return <p className="subtitle-gray-text assessed-by-text">Submitted for assessment</p>;
        }
        if (lesion.excluded) {
            return (
                <p className="subtitle-gray-text assessed-by-text">
                    Submitted for assessment on {formatTimeWithHours(lesion.result.reportDate)}
                </p>
            );
        }
        return (
            <p className="subtitle-gray-text assessed-by-text">
                Assessed by <DermLabelling dermResult={lesion?.result} /> on{" "}
                {formatTimeWithHours(lesion.result.reportDate)}
            </p>
        );
    }

    private static getLesionErrorReason(result: IResult) {
        try {
            if (result.error) {
                let reason = JSON.parse(result.error.split("Reason:")[1]);
                reason = JSON.parse(reason)
                    .errorDetails.map((error: any) => error.errorDescription)
                    .join(" ");
                return reason;
            }
            return "DERM AI was unable to assess the image";
        } catch (err) {
            return DermErrorResponse.SOMETHING_WENT_WRONG;
        }
    }

    private getDiagnosis() {
        const { lesion } = this.props;
        if (getLesionExclusions(lesion).length || !lesion.result) {
            return DATA_IS_NOT_AVAILABLE_TEXT;
        }
        return (
            lesion.result?.lesions?.map((lesionResult) => lesionResult.suspectedDiagnosis).join(", ") ||
            DATA_IS_NOT_AVAILABLE_TEXT
        );
    }

    private getManagementOutcome() {
        const { lesion } = this.props;
        if (getLesionExclusions(lesion).length || !lesion.result) {
            return DATA_IS_NOT_AVAILABLE_TEXT;
        }
        return getLesionManagementOutcome(lesion);
    }

    public render() {
        const { lesion } = this.props;

        return (
            <>
                <Grid className="case-status">
                    {this.getAssessmentData()}
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <p>Assessment Status</p>
                            <Segment>{getLesionAssessmentStatus(lesion)}</Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <p>DERM Classification</p>
                            <Segment>{this.getDiagnosis()}</Segment>
                        </Grid.Column>
                        <Grid.Column width={12} verticalAlign="bottom">
                            <Segment>{this.getManagementOutcome()}</Segment>
                        </Grid.Column>
                    </Grid.Row>
                    {this.getErrorInfo()}
                </Grid>
            </>
        );
    }
}

export default LesionAIAssessment;
