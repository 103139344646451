import { IAuditConfiguration } from "model/auditConfig";
import { CaseStatus } from "model/caseStatus";
import { ReviewType } from "model/reviewType";
import { OrganisationMenuItems } from "model/administrationPages";
import PatientDetailsIntegration from "model/integrations";
import QuestionSubTypeEnum from "model/questionSubType";
import { IRegistrationTexts } from "./registration";

export interface IOrganisation {
    autoAssignConfig?: IAutoAllocation;
    type: OrganisationType;
    definedPersonalData: IDefinedPersonalData[];
    assessment: string;
    research: string;
    assessmentPatient: string;
    researchPatient: string;
    primaryConsent: string;
    secondaryConsent: string;
    consentCreationDate: Date;
    assessmentNsc: string;
    researchNsc: string;
    uuid: string;
    name: string;
    lesionHistoryQuestions: IMedicalHistoryQuestion[];
    skinComplaintQuestions: IMedicalHistoryQuestion[];
    medicalHistoryQuestions: IMedicalHistoryQuestion[];
    nscMedicalHistoryQuestions: IMedicalHistoryQuestion[];
    biopsyQuestions: IMedicalHistoryQuestion[];
    lesionExclusions: ILesionExclusion[];
    dermCredentials: IDermCredentials;
    locations: ILocation[];
    lesionsLimit: number;
    pathway: string;
    parentUuid: string;
    parentName: string;
    creationDate: string;
    reviewDiagnoses: IReviewDiagnosis[];
    reviewManagementOptions: IManagementOptions[];
    templates: ITemplate[];
    allocationConfiguration?: IAllocation[];
    notificationConfiguration?: INotificationConfiguration[];
    guidance: string;
    minimumAge: number;
    logo: ILogo;
    overdueDefinitions: IOverdue[];
    workingDays: string[];
    codeName?: string;
    organisationEmail?: string;
    nonSkinCancerAllowed: boolean;
    escalationPlans?: IReviewEscalation[];
    allowHomeInitiatedCases?: boolean;
    patientDetailsIntegrationType?: PatientDetailsIntegration;
    isSecondReadOrganisation?: boolean;
    showAutomatedDecisionConsent: boolean;
    automatedDecisionConsentClinician?: string;
    automatedDecisionConsentPatient?: string;
    blockCasesWithoutAutomatedDecisionConsent?: boolean;
    auditConfiguration: IAuditConfiguration[];
    allowAuditRouting?: boolean;
    mfaEnabled: boolean;
    optionalCallbackOnRefer?: boolean;
    registrationTexts?: IRegistrationTexts;
}

export interface IConsentData {
    assessment: string;
    research: string;
    assessmentPatient: string;
    researchPatient: string;
    primaryConsent: string;
    secondaryConsent: string;
    assessmentNsc: string;
    researchNsc: string;
    showAutomatedDecisionConsent: boolean;
    automatedDecisionConsentClinician: string;
    automatedDecisionConsentPatient: string;
    blockCasesWithoutAutomatedDecisionConsent: boolean;
}

export interface IOverdue {
    lastModificationDate: string;
    removed: boolean;
    createdBy: string;
    caseStatus: string;
    lastModifiedBy: string;
    creationDate: string;
    uuid: string;
    organisationUuid: string;
    enabled: boolean;
    overdueHours: number;
    overdueMinutes: number;
    inheritedFromParent?: boolean;
}

export interface IOverdueSettings {
    organisation: IOrganisation;
}

interface ILogo {
    createdBy: string;
    createdByName: string;
    creationDate: string;
    creatorOrganisationName: string;
    imageFile: string;
    imageName: string;
    organisationUuid: string;
    status: string;
    type: string;
    uuid: string;
    removed: boolean;
}

export interface INotificationConfiguration {
    removed?: boolean;
    uuid?: string;
    organisationEmail: string;
    organisationUuid: string;
    notificationType: NotificationType;
    channel?: NotificationChannel;
    caseStatus?: CaseStatus | null;
    notificationTitle?: string;
    notificationContent?: string;
    notificationArray?: any;
    targets?: string[];
    copyOfUuid?: string;
}

export enum NotificationTypeText {
    reportGenerated = "Report generated",
    caseOverdue = "Overdue",
}

export enum NotificationType {
    reportGenerated = "reportGenerated",
    caseOverdue = "caseOverdue",
}

export enum NotificationChannel {
    email = "EMAIL",
}

export enum NotificationChannelUpper {
    EMAIL = "Email",
}

export interface IAllocation {
    allocationOrder: number;
    guidanceValue: GuidanceValue;
    quantifier: QuantifierValue;
    allocation: AllocationValue;
    immediateResults: boolean;
    removed: boolean;
    uuid?: string;
    modified?: boolean;
    organisationUuid?: string;
    copyOfUuid?: string | null;
    isForUnconsentedAutomatedDecisionCases?: boolean | null;
}

export interface IAutoAllocation {
    organisationUuid: string;
    isAutoAllocation: boolean;
    isAllAvailableDermatologists: boolean;
    availableDermatologistUuidList: string[];
}

export enum AllocationValue {
    CLIENT = "CLIENT",
    SA = "SA",
    NONE = "NONE",
}

export enum AllocationText {
    CLIENT = "Client Assessment",
    SA = "Safety Net Assessment",
    NONE = "No Assessment (Complete Case)",
}

export enum QuantifierValue {
    ALL = "ALL",
    ANY = "ANY",
}

export enum GuidanceValue {
    URGENT_REFER = "Urgent Refer",
    REFER = "Refer",
    EXCLUSION = "Exclusion",
    EXCEPTION = "Exception",
    DISCHARGE = "Discharge",
}

export interface IReviewDiagnosis {
    diagnosis: string;
    removed: boolean;
    managementOptions: IManagementOptions[];
    uuid: string;
    organisationUuid: string;
    reviewType: ReviewType;
    copyOfUuid?: string;
    additionalInformation?: string;
    freeText: boolean;
    displayOrder?: number;
}

export type INewReviewDiagnoses = {
    organisationUuid: string;
    diagnosis: string;
    managementOptions: INewManagementOptions[];
    freeText: boolean;
    reviewType: ReviewType;
    uuid?: string;
    removed?: boolean;
    displayOrder?: number;
};

export interface IManagementOptions {
    management: string;
    uuid: string;
    removed: boolean;
    managementUuid: string;
    freeText: boolean;
    reviewType: ReviewType;
    organisationUuid?: string;
    refer: boolean;
    discharge: boolean;
    keyMessage?: string;
    safetyNetting?: string;
    copyOfUuid?: string;
}

export interface INewManagementOptions {
    managementUuid: string;
}

export interface IReviewEscalation {
    escalationPlan: string;
    removed: boolean;
    uuid: string;
    organisationUuid: string;
    reviewType: ReviewType;
    copyOfUuid?: string;
    freeText: boolean;
}

export interface INewReviewEscalation {
    escalationPlan: string;
    organisationUuid: string;
    freeText: boolean;
}

export interface ILocation {
    uuid: string;
    name: string;
    locationOrder: number;
    description: string;
    removed: boolean;
    organisationUuid?: string;
}

export interface ITemplate {
    uuid?: string;
    removed?: boolean;
    name: string;
    fields: (ITemplateField | IStructuredTemplateField)[];
    organisationUuid?: string;
    copyOfUuid: string;
    isStructured?: boolean;
}

export interface ITemplateField {
    name: string;
    content: string;
}

export interface IStructuredTemplateField {
    uuid?: string;
    question?: string;
    questionType?: TemplateFieldQuestionType | "";
    parentFieldUuid?: string;
    responseOptions?: string[] | ITemplateFieldOption[];
    required?: boolean;
    childStructuredFields?: IStructuredTemplateField[];
    templateUuid?: string;
    removed?: boolean;
    isEdited?: boolean;
    organisationUuid?: string;
}

export interface ITemplateFieldOption {
    option: string;
    uuid: string;
    templateFieldUuid: string;
    removed: false;
    createdBy: string;
    creationDate: Date;
    lastModificationDate: Date;
    lastModifiedBy: string;
}

export enum TemplateFieldQuestionType {
    YES_NO = "Yes/No",
    DROPDOWN = "Dropdown",
    MULTIPLE_CHOICE = "Multiple choice",
    FREE_TEXT = "Free text",
}

export interface IDermCredentials {
    host: string;
    user: string;
}

export interface ILesionExclusion {
    removed: boolean;
    exclusion: string;
    nonExcluding: boolean;
    exclusionOrder: number;
    uuid: string;
    organisationUuid: string;
    copyOfUuid: string;
}

export interface IDefinedPersonalData {
    copyOfUuid: string | undefined;
    propertyName: string;
    displayName: string;
    displayOrder: number;
    type: DefinedPersonalDataType;
    required: boolean;
    uuid: string;
    identifier: boolean;
    removed: boolean;
    organisationUuid: string;
    validationProperty: string | undefined;
    registration?: boolean;
    options?: string[];
    isRequestedAtManualCreation?: boolean;
    integrationSourceSystem?: string;
    integrationSourcePropertyName?: string;
}

export interface INewDefinedPersonalData {
    propertyName: string;
    displayName: string;
    displayOrder: number;
    type: DefinedPersonalDataType;
    required: boolean;
    identifier: boolean;
    validationProperty: string | undefined;
    options?: any;
    newOption?: any;
    manualPatientСreation?: boolean;
    integrationSourceSystem?: string;
    integrationSourcePropertyName?: string;
}

export interface IDefinedPersonalDataErrors {
    displayNameError: string;
    displayOrderError: string;
    identifierError: string;
    propertyNameError?: string;
    requiredError: string;
    typeError?: string;
    newOption?: string;
    options?: string;
}

export interface IIdentifierValidationErrors {
    identifierError: string;
    requiredError?: string;
}

export enum DefinedPersonalDataType {
    TEXT = "text",
    DATE = "date",
    NUMBER = "number",
    OPTIONS = "options",
    BOOLEAN = "boolean",
}

export enum DefinedPersonalDataValidationText {
    none = "None",
    minimumAge = "Minimum Age",
    nhsNumber = "NHS#",
    email = "Email",
    hospitalNumber = "Hospital Number",
}

export enum PersonalDataFieldCoreProperties {
    propertyName = "propertyName",
    required = "required",
    displayName = "displayName",
    displayOrder = "displayOrder",
    identifier = "identifier",
    type = "type",
    validationProperty = "validationProperty",
    isRequestedAtManualCreation = "isRequestedAtManualCreation",
    integrationSourcePropertyName = "integrationSourcePropertyName",
    integrationSourceSystem = "integrationSourceSystem",
}

export enum IDefinedValidationType {
    none = "none",
    minimumAge = "minimumAge",
    nhsNumber = "nhsNumber",
    email = "email",
    hospitalNumber = "hospitalNumber",
}

export enum IDefinedPropertyName {
    hospitalNumber = "hospitalNumber",
    nhsNumber = "nhs",
}

export enum OrganisationType {
    STUDY = "STUDY",
    CLINIC = "CLINIC",
}

export interface IHistoryQuestion {
    uuid?: string;
    required: boolean;
    question: string;
    answerType: IHistoryAnswerType;
    order: number;
    questionType: IHistoryQuestionType;
    options?: IHistoryQuestionOptions[];
    neutralValue?: string;
    questionSubType?: QuestionSubTypeEnum.SKIN_TYPE;
}

export enum IHistoryQuestionType {
    MEDICAL = "MEDICAL",
    NSC_MEDICAL = "NSC_MEDICAL",
    LESION = "LESION",
    SKIN_COMPLAINT = "SKIN_COMPLAINT",
    BIOPSY = "BIOPSY",
    EXCLUSION = "EXCLUSION",
}

export interface IMedicalHistoryQuestion {
    lastModificationDate: string;
    question: string;
    removed: boolean;
    createdBy: string;
    lastModifiedBy: string;
    answerType: IHistoryAnswerType;
    questionOrder: number;
    creationDate: string;
    questionType: string;
    organisationUuid: string;
    required: boolean;
    options: IMedicalHistoryQuestionOption[];
    uuid: string;
    neutralValue?: string;
    copyOfUuid?: string;
    questionSubType?: QuestionSubTypeEnum.SKIN_TYPE;
}

export interface IMedicalHistoryQuestionOption {
    option: string;
    optionOrder: number;
    removed: false;
    additionalInformation: boolean;
    significant: boolean;
    supplementaryText?: string;
}

export enum IHistoryAnswerType {
    CHECKBOX = "CHECKBOX",
    TEXT = "TEXT",
    OPTION = "OPTION",
    OPTIONS = "OPTIONS",
}

export interface IHistoryQuestionOptions {
    additionalInformation: boolean;
    option: string;
    optionOrder: number;
    removed: boolean;
    supplementaryText?: string;
}

export interface INewManagementOption {
    management: string;
    refer: boolean;
    discharge: boolean;
    freeTextManagement: boolean;
    reviewType?: ReviewType;
    keyMessage?: string;
    safetyNetting?: string;
}

export interface IOverdueUpdate {
    overdueHours: number;
    overdueMinutes: number;
    enabled: boolean;
    organisationUuid: string;
}

export interface IQuestionaryAnswers {
    [key: string]: { type: IHistoryAnswerType; value: string | { [key: string]: boolean } };
}

export interface IPersonalDataCoreField {
    propertyName: string;
    displayName: string;
    displayOrder: number;
    type: DefinedPersonalDataType;
    required: boolean;
    identifier: boolean;
    validationProperty: string;
    coreField: boolean;
    manualСaseСreation?: boolean;
    integrationSourceSystem?: string;
    integrationSourcePropertyName?: string;
}

export interface IOwnProperties {
    removed: string[];
    added: string[];
    edited?: string[];
}

export interface IModifiedInheritedProperties {
    [OrganisationMenuItems.CASE_ALLOCATION]?: IOwnProperties | string;
    [OrganisationMenuItems.PERSONAL_DATA]?: IOwnProperties | string;
    [OrganisationMenuItems.NOTIFICATIONS]?: IOwnProperties | string;
    [OrganisationMenuItems.TEMPLATES]?: IOwnProperties | string;
    [OrganisationMenuItems.MEDICAL_HISTORY]?: IOwnProperties | string;
    [OrganisationMenuItems.LESION_HISTORY]?: IOwnProperties | string;
    [OrganisationMenuItems.BIOPSY]?: IOwnProperties | string;
    [OrganisationMenuItems.EXCLUSIONS]?: IOwnProperties | string;
    [OrganisationMenuItems.REVIEW_SETTINGS]?: IOwnProperties | string;
    [OrganisationMenuItems.OVERDUE_SETTINGS]?: IOwnProperties | string;
}

export interface IOrganisationPersonalData {
    definedPersonalData: IDefinedPersonalData[];
    patientDetailsIntegrationType?: PatientDetailsIntegration;
    isOrganisationDataFetched: boolean;
}

export const validationTypeNoneOption = {
    key: DefinedPersonalDataValidationText.none,
    text: DefinedPersonalDataValidationText.none,
    value: IDefinedValidationType.none,
};

export const validationTypeMinumumAgeOption = {
    key: DefinedPersonalDataValidationText.minimumAge,
    text: DefinedPersonalDataValidationText.minimumAge,
    value: IDefinedValidationType.minimumAge,
};

export const validationTypeNhsNumberOption = {
    key: DefinedPersonalDataValidationText.nhsNumber,
    text: DefinedPersonalDataValidationText.nhsNumber,
    value: IDefinedValidationType.nhsNumber,
};

export const validationTypeEmailOption = {
    key: DefinedPersonalDataValidationText.email,
    text: DefinedPersonalDataValidationText.email,
    value: IDefinedValidationType.email,
};

export const validationTypeHospitalNumberOption = {
    key: DefinedPersonalDataValidationText.hospitalNumber,
    text: DefinedPersonalDataValidationText.hospitalNumber,
    value: IDefinedValidationType.hospitalNumber,
};

export interface IOrganisationData {
    organisationUuid: string;
    organisationName: string;
}

// This needs to match the definition from ms-shared

export enum SkinToneAnswers {
    TYPE_1 = "FST 1: Always burns. Never tans.",
    TYPE_2 = "FST 2: Almost always burns. Tans rarely, if at all.",
    TYPE_3 = "FST 3: Sometimes burns after long or intense sun exposure. Tans gradually to olive.",
    TYPE_4 = "FST 4: Could possibly burn after lengthy exposure to intense sunshine. Tans with ease to moderate brown.",
    TYPE_5 = "FST 5: Rarely burns, if at all. Almost always tans.",
    TYPE_6 = "FST 6: Never burns. Always Tans.",
}
export enum SkinToneSupplementaryInformation {
    TYPE_1 = "People in this category usually have the palest skin and light blue, light grey or light green coloured eyes. They also typically have red or light blonde hair and many freckles on their body. In the clinical world, this category is known as Fitzpatrick Skin Type (Phototype) 1.",
    TYPE_2 = "People in this category usually have naturally pale skin and blue, grey or green coloured eyes. They could have light or dark hair and have freckles on their body. In the clinical world, this category is known as Fitzpatrick Skin Type (Phototype) 2.",
    TYPE_3 = "People in this category usually burn only after long or intense sun exposure but generally tan gradually to olive. They usually have medium white to olive skin, and blue, green, hazel or light brown eyes. They may have dark blonde or chestnut hair and have a few freckles. In the clinical world, this category is known as Fitzpatrick Skin Type (Phototype) 3.",
    TYPE_4 = "People in this category usually tan easily and could possibly burn but only after lengthy exposure to intense sunshine. They tend to have olive skin and brown eyes. They usually have dark brown hair and have very few freckles. In the clinical world, this category is known as Fitzpatrick Skin Type (Phototype) 4.",
    TYPE_5 = "People in this category will find that their skin can darken easily with sun exposure and doesn’t generally burn unless exposure to the sun is very intense. They tend to have naturally brown skin and dark brown eyes. They usually have dark hair and have no freckles. In the clinical world, this category is known as Fitzpatrick Skin Type (Phototype) 5.",
    TYPE_6 = "People in this category do not burn and always tan. They have the most deeply pigmented skin. They tend to have dark brown or black coloured skin, and dark brown or brownish black eyes. They usually have black hair and no freckles. In the clinical world, this category is known as Fitzpatrick Skin Type (Phototype) 6.",
}
