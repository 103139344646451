import { DermErrorResponse } from "model/errors";

export const getDermErrorJSON = (dermError: any) => {
    if (dermError) {
        try {
            const dermResponseError = JSON.parse(dermError.split("Reason:")[1]);
            const dermResponseErrorJson = JSON.parse(dermResponseError);
            return dermResponseErrorJson;
        } catch (e) {
            return undefined;
        }
    }
    return undefined;
};

export const getDermError = (dermResponse: any) => {
    if (dermResponse.error) {
        try {
            const dermResponseError = JSON.parse(dermResponse.error.split("Reason:")[1]);
            const dermResponseErrorJson = JSON.parse(dermResponseError);

            if (dermResponseErrorJson.errorDetails) {
                return dermResponseErrorJson.errorDetails.map((error: any) => error.errorDescription).join(" ");
            }
            return DermErrorResponse.SOMETHING_WENT_WRONG;
        } catch (e) {
            return DermErrorResponse.SOMETHING_WENT_WRONG;
        }
    }

    return "";
};
