import { Component, ReactNode } from "react";
import { Grid, Header } from "semantic-ui-react";

import { getDermError } from "helpers/derm";
import getLesionExclusions from "helpers/exclusion";

import { AnalysisStatus, ICase, ILesion } from "model/case";
import { AllLesionLocation } from "model/lesionLocation";
import { GuidanceValue } from "model/organisation";

interface IImmediateResultLesionsAssessmentProps {
    lesion: ILesion;
    lesionNumber: number;
    caseObject: ICase;
    guidanceValue: GuidanceValue;
}

interface IImediateResultlesionAssessmenState {
    isError: boolean;
}

class ImmediateResultLesionAssesment extends Component<
    IImmediateResultLesionsAssessmentProps,
    IImediateResultlesionAssessmenState
> {
    constructor(props: any) {
        super(props);
        const { lesion } = this.props;
        const { result } = lesion;
        this.state = {
            isError: !result || !!result.error || result.analysisStatus === AnalysisStatus.STATUS_ERROR,
        };
    }

    private getDiagnosis() {
        const { lesion } = this.props;
        const { isError } = this.state;
        const exclusions = getLesionExclusions(lesion);

        if (exclusions.length) {
            return <p>{`Exclusion, ${exclusions.join(", ")}`}</p>;
        }

        if (isError) {
            return "";
        }

        return (
            <p>
                {lesion.result?.lesions
                    ?.map(
                        (lesionResult) =>
                            `${lesionResult.suspectedDiagnosis} - ${lesionResult.referralRecommendation.plainText}`
                    )
                    .join(", ")}
            </p>
        );
    }

    private getInterpretationGuidance(): ReactNode {
        const { lesion } = this.props;
        const { isError } = this.state;
        const exclusions = getLesionExclusions(lesion);

        if (exclusions.length) {
            return "";
        }

        if (isError) {
            return (
                <Grid columns="2" className="tab">
                    <Grid.Row>
                        <Grid.Column width="2">
                            <b>Not Assessed: </b>
                        </Grid.Column>
                        <Grid.Column width="14">{this.getErrorInfo()}</Grid.Column>
                    </Grid.Row>
                </Grid>
            );
        }

        return (
            <p className="tab">
                {lesion.result?.lesions
                    ?.map((lesionResult) => {
                        const { interpretationGuidance } = lesionResult;
                        return interpretationGuidance?.plainText;
                    })
                    .join(", ")}
            </p>
        );
    }

    private getErrorInfo() {
        const { lesion } = this.props;

        if (!lesion?.result) {
            return "DERM rejected lesion";
        }

        if (lesion?.result && lesion.result.error) {
            return `DERM returned validation error: ${getDermError(lesion.result)}`;
        }

        if (lesion.result.analysisStatus === AnalysisStatus.STATUS_ERROR) {
            return "DERM returned processing error";
        }

        return "Unknown error";
    }

    public render() {
        const { lesionNumber, lesion } = this.props;
        const lesionPaddingStyle = lesionNumber !== 1 ? "lesion-top-padding" : "";
        const lesionDescription = lesion.description ? ` (${lesion.description})` : "";
        const lesionTitle = `Lesion ${lesionNumber}. ${AllLesionLocation[lesion.bodyPart]}`;

        return (
            <div className="tab-div">
                <Header className={`without-bottom-margin ${lesionPaddingStyle}`} as="h4">
                    <b>{lesionTitle}</b>
                    {lesionDescription}:
                </Header>
                {this.getDiagnosis()}
                {this.getInterpretationGuidance()}
            </div>
        );
    }
}

export default ImmediateResultLesionAssesment;
