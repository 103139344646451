import { FC } from "react";

import { IBaseResult, IResult } from "model/case";

import { getDermErrorJSON } from "helpers/derm";

interface IDermLabelling {
    dermResult?: IResult;
}

const DermLabelling: FC<IDermLabelling> = ({ dermResult }) => {
    const resultData: IBaseResult = dermResult?.error ? getDermErrorJSON(dermResult.error) : dermResult;
    const dermVersionParts = resultData?.dermProductNumber?.split("-");
    const link = resultData?.medicalLabellingGraphic;
    const version = dermVersionParts?.length > 1 ? dermVersionParts[1] : "";
    return (
        <>
            DERM{" "}
            {version ? (
                <>
                    (
                    {link ? (
                        <a href={link} target="_blank" rel="noopener noreferrer">
                            {version}
                        </a>
                    ) : (
                        version
                    )}
                    )
                </>
            ) : (
                ""
            )}
        </>
    );
};

export default DermLabelling;
